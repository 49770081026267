ul#sellcar-img {
    padding: 0px !important;
    display: flex;
    list-style-type: none;
    justify-content: space-between;
  }
  .sellcar .mov {
    padding-left: 47px;
  }
  .sellcar form input {
    background: #ebebeb;
    border: 0px !important;
    border-radius: 0px;
    padding: 17px !important;
    font-family: "Roboto-Regular";
    font-size: 18px;
  }
  .sellcar form textarea {
    background: #ebebeb;
    border: 0px !important;
    border-radius: 0px;
    padding: 17px !important;
    font-family: "Roboto-Regular";
    font-size: 18px;
  }
  .sellcar form input::placeholder {
    color: #b3b5b8;
  }
  .sellcar form textarea::placeholder {
    color: #b3b5b8;
  }
  .sellcar form label {
    color: #b3b5b8;
    font-size: 20px;
    font-family: "Roboto-Regular";
  }
  .sellcar h3 {
    font-size: 34px;
    font-family: "Roboto-Regular";
    font-weight: 700;
    color: #303842;
  }
  .sellcar p {
    font-size: 15.9px;
    font-family: "Roboto-Regular";
    color: #707070;
  }
  .sellcar .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-family: 'Poppins-Regular';
  }
  .BuyersLeaderboard {
    background: url("../images/buyersleadermode.jpg");
    background-size: 100% 100%;
  }
  
  @media (max-width:769px){
    .sellcar .mov {
      /* padding-left: 81px; */
  }
  }
  @media (max-width:576px){
    .sellcar .mov {
      padding-left: 0px !important;
  }
  }
  @media (max-width:520px){
    .sellcar .mov {
      padding-left: 15px !important;
  }
  }
  @media (max-width:480px){
    .sellcar .form-submit {
      transform: translate(-5px);
  }
  }