/* Auto Loan Css Start Here */
.loan-left{
  padding: 40px 40px;
  background-color: whitesmoke;
}
.loan-left .inputContainer {
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
  margin-bottom: 15px;
}
.loan-left .inputClass {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #cfcfcf;
}
.loan-right {
  padding: 40px 40px;
  background-color: white;
  border: 1px solid #e7e7e7;
}
.loan-right .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loan-right .rate-month {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
}

.loan-right .breakdown{
  font-size: 27px;
  font-weight: bold;
}

.loan-right .gray-content{
  color: #8d8888;
  font-size: 18px;
  padding: 10px 0px;
}

.loan-right .gray-Bcontent{
  color: #4a4848;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 0px;
}


.auto_loan {
    padding: 60px 0;
  }
  .auto_loan nav .nav-tabs {
    border: 0;
  }
  .auto_loan nav a.nav-link {
    flex: 1;
    background: #2d2d371f;
    border-radius: 0;
    border: 0;
    font-size: 12px;
    color: #1b1b20;
    font-family: "Poppins-Regular";
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
  }
  .auto_loan nav a.nav-link.active {
    background: #1b1b20;
    color: #fff;
  }
  .auto_loan nav .nav-tabs {
    border: 0;
  }
  .auto_loan nav a.nav-link {
    flex: 1;
    background: #2d2d371f;
    border-radius: 0;
    border: 0;
    font-size: 12px;
    color: #1b1b20;
    font-family: "Poppins-Regular";
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
  }
  .auto_loan nav a.nav-link.active {
    background: #1b1b20;
    color: #fff;
  }
  .auto_loan .pricing_details {
    background: #1b1b20;
    padding: 30px 30px;
  }
  .auto_loan .pricing_details label {
    font-size: 13px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
    margin: 0;
    width: 30rem;
  }
  .auto_loan .pricing_details .form-control {
    border: 0;
    border-radius: 0;
    background: #e7e7e7;
    font-family: "Roboto-Regular";
  }
  .auto_loan .pricing_details .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .auto_loan .pricing_details .checkboxes {
    padding-top: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .auto_loan .pricing_details .checkboxes input {
    width: unset;
    margin-right: 10px;
  }
  .auto_loan .pricing_details .checkboxes label {
    font-size: 17px;
    color: #8b8c8d;
    font-family: "Roboto-Regular";
    padding-left: 40px;
    position: relative;
  }
  .auto_loan .pricing_details .checkboxes input {
    opacity: 0;
  }
  .auto_loan .pricing_details .checkboxes span.checkmark {
    width: 28px;
    height: 29px;
    background: #fff;
    position: absolute;
    left: 0;
    border-radius: 2px;
    border: 2px solid #fff;
    top: -1px;
  }
  .auto_loan .pricing_details .checkboxes input:checked ~ span.checkmark {
    background: #fff;
    border-color: #000;
    box-shadow: 0px 0px 0px 2px #fff;
    border-radius: 0;
  }
  .auto_loan button.calculate_btn.btn {
    width: 100%;
    border-radius: 0;
    background: #d10808;
    color: #fff;
    font-size: 20px;
    font-family: "Roboto-Regular";
    padding: 14px 10px;
    border: 0;
    margin-top: 12px;
    cursor: pointer;
    margin-bottom: 12px;
  }
  .auto_loan .top_strip {
    background: #d10808;
    padding: 20px 30px;
    margin-left: -30px;
  }
  .auto_loan .top_strip h3 {
    margin: 0;
    font-size: 30px;
    color: #ffffff;
    font-family: "Roboto-Regular";
    font-weight: 500;
  }
  .auto_loan .all_payments {
    padding: 50px 0;
  }
  .auto_loan .all_payments .pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
  }
  .auto_loan .all_payments .pay h5.property {
    font-size: 21px;
    color: #d32626;
    font-family: "Poppins-Regular";
    margin: 0;
  }
  .auto_loan .all_payments .spacer {
    padding: 10px 0;
  }
  .auto_loan .all_payments .pay h5.value {
    margin: 0;
    font-size: 21px;
    color: #303842;
    font-family: "Poppins-Regular";
  }
  .auto_loan .all_payments .buton-group {
    padding-top: 30px;
  }
  .auto_loan .all_payments .buton-group a {
    color: #8b8c8d;
    font-family: "Roboto-Regular";
    font-weight: 500;
    text-decoration: underline;
  }

  @media (max-width: 800px) {
    .auto_loan {
      padding: 42px 0;
    }
  }
  


  @media (max-width: 575px) {
    .auto_loan .top_strip {
      margin-left: 0px;
    }
  }

  @media (max-width: 480px) {
    .auto_loan {
      padding: 36px 0;
    }
    .loan-left .inputClass {
      margin: unset !important;
    }
  }
  @media (max-width: 375px) {
    .loan-left .inputClass {
      margin: unset !important;
    }
  }
  /* Auto Loan Css End Here */
  