body{
    overflow-x: hidden;
}
.part-box img {
    width: 100%;
}
.part-box {
    background-color: #707070;
    margin: 10px;
}
.part-box h5 {
    font-size: 10px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}
.customize-banner-img img {
    position: relative;
    width: 100%;
}
section.cutomize-sec {
    position: relative;
}
.customize-select {
    background: #ffffff42;
    padding-left: 20px;
    padding-right: 26px;
    padding-bottom: 40px;
    padding-top: 18px;
    z-index: 999999;
}
.customize-list li h5 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
}
.customize-list li {
    list-style: auto;
    color: #ffffff;
}
.customize-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: initial;
    align-items: center;
}
.customize-head h2 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    text-transform: uppercase;
}
.customize-list li {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
    text-transform: capitalize;
    border-bottom: 8px solid #707070;
    padding: 15px 0px;
}

.customize-icons-all ul {
    list-style: none;
}
.customize-icons-all ul li a img {
    padding: 30px 0px;
    z-index: 99999;
}
.customize-iconsz- {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 250px;
}
header .navbarMenu ul.navItems li.menu-items a {
    z-index: 999999;
}
section.customize-main-heading {
    background-color: #212020;
}
section.customize-main-heading h2 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Montserrat-Regular';
    margin: 0;
    text-align: center;
    padding: 8px;
}

section.time-sec {
    background-image: url('../images/footer-line.png');
}

.parts-flex{
    background-image: url('../images/car-ground.png');
    background-repeat: no-repeat;
}

/* Responsive section  */
@media (max-width: 1150px) {
    .customize-iconsz- {
        right: 30px;
        top: 185px;
    }

}
@media (max-width: 768px) {
    .customize-icons-all ul li a img {
        padding: 17px 0px;
        width: 45%;
    }
    .customize-head h2 {
        font-size: 20px;
    }
    .customize-list li {
        font-size: 11px;
    }
    .part-box h5 {
        font-size: 8px;
    }
    section.customize-main-heading h2 {
        font-size: 16px;
    }
}
@media (max-width: 480px) {
    .customize-head h2 {
        font-size: 8px;
    }
    .customize-list li {
        font-size: 6px;
        border-bottom: 3px solid #707070;
        padding: 5px 0px;
    }
    .customize-icons-all ul li a img {
        padding: 6px 0px;
        width: 40%;
    }
    .customize-select {
        padding-left: 11px;
        padding-right: 14px;
        padding-bottom: 16px;
        padding-top: 18px;
    }
    
    .customize-iconsz- {
        right: -26px;
        top: 130px;
    }
    .part-box h5 {
        font-size: 7px;
    }
   
}

/* End of Responsive section  */