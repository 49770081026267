/* Product Detail Css Start Here */
.product-detail .price{
  margin-right: 20px;
  color: #000000;
  font-size: 50px;
  font-weight: 500;
  font-family: Impact;
  font-style: italic;
}
.product-detail .descriptions h3{
  margin-right: 20px;
  color: #d33636;
  font-size: 20px;
  font-weight: 500;
  font-family: Impact;
  font-style: italic;
}
.product-detail .offer-text{
  color: #0a0a0a;
  font-family: Impact;
  font-size: 24px;
  line-height: 22px;
  font-style: italic;
  TEXT-ALIGN: center;
  margin: 36px 0px 0px 0px;
}
.product-detail .heart {
  width: 22px;
}
.product-detail {
    padding: 60px 0 100px;
  }
  .product-detail .inner-image-container{
    padding: 10px 10px;
  }
  .product-detail ul.breadcrumbs {
    padding: 0;
    margin: 0 0 50px 0;
    list-style: none;
  }
  .product-detail ul.breadcrumbs li {
    display: inline-block;
    font-family: "Montserrat-Regular";
    font-weight: 500;
    margin-right: 3px;
  }
  .product-detail ul.breadcrumbs li a {
    color: #000;
  }
  .product-detail .single-product-image {
    margin-bottom: 30px;
  }
  .product-detail .single-product-image .full_img img {
    width: 100%;
  }
  .product-detail .single-product-image .multiple-images .img_box img {
    width: 100%;
    padding-right: 5px;
  }
  .product-detail .product-contents .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2px;

  }
  .product-detail .product-contents .topbar .headings {
    display: flex;
    align-items: center;
  }
  .product-detail .product-contents .topbar .headings h4.name {
    margin-right: 20px;
    color: #000000;
    font-size: 28px;
    font-weight: 500;
    font-family: Impact;
    font-style: italic;
  }
  .product-detail .product-contents .topbar .headings h4.price {
    color: #d70808;
    font-weight: 700;
    font-size: 28px;
  }
  .product-detail .product-contents .topbar .favourite_btn input {
    opacity: 0;
  }
  .product-detail .product-contents .topbar .favourite_btn span.checkmark i {
    font-size: 25px;
  }
  .product-detail
    .product-contents
    .topbar
    .favourite_btn
    input:checked
    ~ span.checkmark
    i {
    color: #d70808;
  }
  .product-detail .product-contents p {
    color: #000000;
    font-family: Impact;
    font-size: 25px;
    line-height: 28px;
    font-style: italic;
    text-transform: uppercase;
  }
  .product-detail .product-contents .count {
    color: #000000;
    font-family: Impact;
    font-size: 23px;
    line-height: 23px;
    font-style: italic;
    text-transform: uppercase;
  }
  .product-detail .product-contents .rating {
    margin-bottom: 30px;
  }
  .product-detail .product-contents ul.stars {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .product-detail .product-contents ul.stars li {
    display: inline-block;
    margin-right: 5px;
  }
  .product-detail .product-contents ul.stars li i {
    color: #e8bb3f;
    font-size: 20px;
  }
  .product-detail .product-contents .sizes_variations {
    display: flex;
    align-items: center;
  }
  .product-detail .product-contents .sizes_variations .quantity {
    flex: 1;
    margin-right: 20px;
  }
  .product-detail .product-contents .form-group {
    display: flex;
    align-items: center;
  }
  .product-detail .product-contents .form-group label {
    margin: 0;
    font-family: 'Poppins-Regular';
    font-size: 15px;
    color: #141414;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 15px;
  }
  .product-detail .product-contents .form-group .form-control {
    border: 1px solid #707070 !important;
    color: #000;
    font-family: 'Poppins-Regular';
    height: 45px;
  }
  .product-detail .product-contents .sizes_variations .quantity .form-control {
    width: 60px;
    text-align: center;
  }
  .product-detail .product-contents .button-group {
    margin-top: 30px;
  }
  .product-detail .product-contents .button-group button {
    display: block;
    background: url(../images/contactformsubmit.png);
    background-repeat: no-repeat;
    width: 60%;
    padding: 15px 0px 15px 0px;
    border: 0px;
    color: #fff;
    font-size: 20px;
    font-family: "Montserrat-Regular";
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    background-size: 100% 100%;
    font-size: 17px;
    color: #ffffff;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 600;
  }
  @media (max-width: 575px) {
    .product-detail .product-contents .topbar .headings h4 {
      font-size: 20px !important;
    }
    .product-detail .product-contents .sizes_variations .quantity .form-control {
      text-align: left;
    }
    .product-detail .product-contents .sizes_variations {
      display: block;
    }
    .product-detail .product-contents .sizes_variations .quantity {
      margin-right: 0;
    }
    .product-detail .product-contents .form-group {
      display: block;
      margin-bottom: 10px;
    }
    .product-detail .product-contents .form-group label {
      margin-bottom: 5px;
    }
    .product-detail .product-contents .sizes_variations .quantity .form-control {
      width: 100%;
    }
    .product-detail .product-contents .button-group button {
      width: 100%;
    }
  }
  /* Product Detail Css Start Here */
  