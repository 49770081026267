/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* Begin of david seciton  */
.blog-title-sec .video-container{
    padding-top: 6px;
    padding-bottom: 30px;
}
.blog-title-sec {
    padding-bottom: 50px;
}
.blog-title-sec .blog-title-img{
    height: 350px;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    margin-bottom: 24px;
}

.blog-title-sec .blog-title-img img{
    height: 100%;
    width: 100%;
}

.david-sec .main-head {
    font-size: 34px;
    color: #303842;
    font-weight: 600;
    text-transform: uppercase;
    padding: 30px 0px;
    font-family: 'HighSpeed';
    text-align: center;
}
.david-sec .date {
    font-size: 14px;
    color: #303842;
    margin: 0;
}

.dav-text h3 {
    font-size: 24px;
    color: #244638;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    margin: 0;
}
.dav-text h4 {
    font-size: 18px;
    color: #244638;
    font-family: 'Poppins-Regular';
    font-weight: 500;
}
.dav-pic-text {
    display: flex;
    align-items: center;
}
.blog-title-text p {
    font-size: 17px;
    color: #707070;
    font-family: 'Roboto-Regular';
    font-weight: 400;
}
.blog-para p {
    font-size: 17px;
    color: #707070;
    font-family: 'Roboto-Regular';
    font-weight: 400;
}
section.blog-para-section {
    padding-top: 40px;
    padding-bottom: 70px;
}
.david-head h2 {
    font-size: 34px;
    color: #303842;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
    text-transform: uppercase;
}
section.david-sec {
    padding-top: 30px;
}
.david-head {
    padding: 20px 0px;
}
.dav-text {
    padding-left: 30px;
}
section.blog-sec {
    /* padding: 50px 0px; */
    padding-bottom: 22px;
}
/* End of david section  */

/* Begin of blog card section  */
.blog-card-text h5 {
    font-size: 12px;
    color: #3F51B5;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
}
.blog-card-text h3 {
    font-size: 17px;
    color: #212121;
    font-family: 'Montserrat-Regular';
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-div-for-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.blog-div-for-flex h4 {
    font-size: 12px;
    color: #757575;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
}
.blog-div-for-flex h4 span {
    color: #3F51B5;
    font-weight: 700;
}
.blog-card-text {
    padding: 20px;
}
.blog-all-stuff.card {
    border: none;
    box-shadow: 0px 0px 58px #0000000f;
}
section.blog-sec .col-md-3 {
    padding: 20px;
}
/* End of blog card section   */

/* Begin of responsive  */
@media (max-width:1150px){
    .blog-title-text p {
        font-size: 14px;
    }
    .blog-para p {
        font-size: 14px;
    }
    .blog-card-text h3 {
        font-size: 14px;
    }
    section.blog-sec {
        padding: 20px 0px;
    }

}
@media (max-width:768px){
    .blog-title-text p {
        font-size: 10px;
    }
    .blog-para p {
        font-size: 11px;
    }
    .blog-card-text h3 {
        font-size: 8px;
    }
    .blog-div-for-flex h4 {
        font-size: 10px;
    }
    section.blog-para-section {
        padding-bottom: 10px;
    }
    .david-head h2 {
        font-size: 22px;
    }

}
@media (max-width:480px){
    .david-head h2 {
        font-size: 15px;
    }
    section.blog-para-section {
        padding-top: 10px;
    }
    section.blog-sec {
        padding: 0px 0px;
    }
    .blog-title-text p {
        padding-top: 20px;
    }
    .dav-text h3 {
        font-size: 20px;
    }
    .dav-text h4 {
        font-size: 13px;
    }
    .dav-text {
        padding-left: 20px;
    }

}

/* End of responsive  */