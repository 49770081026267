.terms-condition h1 {
    color: #303842;
    font-weight: 600;
  }
  .terms-condition p {
    font-size: 20px;
    font-family: "Roboto-Regular";
    line-height: 35px;
    color: #515151;
  }
  .terms-condition p .big-letter {
    font-family: "Roboto-Regular";
    font-size: 24px;
    font-weight: 700;
    color: #515151;
  }
  .countrary .big-letter {
    font-size: 24px !important;
    color: #000;
  }
  .countrary p {
    font-size: 18px;
    line-height: 25px;
  }
  .terms-condition p:before {
    /* content: url(../images/termspageline.png);
    position: absolute;
    left: -24%;
    width: 138px;
    overflow: hidden; */
  }
  .countrary p::before {
    /* content: url(../images/termspageline.png);
    position: absolute;
    left: -47%; */
  }
  @media (max-width: 1200px) {
    .countrary p::before {
      display: none;
    }
    .countrary p::before {
      display: none;
    }
    .terms-condition p:before {
      display: none;
    }
  }
  @media (max-width: 992px) {
    .terms-condition p {
      font-size: 17px;
    }
    .terms-condition p .big-letter {
      font-size: 20px;
    }
  }
  