section#contactus p {
    color: #b3b5b8;
  }
  #contactus .contact-info a {
    color: #000 !important;
    font-family: 'Montserrat-Regular';
    font-size: 14px;
    font-weight: 500;
  }
  section#contactus .contact-info{
    background: #e5e5e5;
    width: -moz-fit-content;
    /* width: fit-content; */
    /* width: 65%; */
    text-align: center;
  }
  section#contactus .contact-info::before {
    /* height: 60px;
    width: 70px;
    background: #e5e5e5;
    position: absolute;
    content: "";
    z-index: -1;
    top: 10px;
    left: 22px; */
  }
  #contactus input {
    border: 0 !important;
    background: #ebebeb;
    border-radius: 0px !important;
    padding: 16px;
    width: 100%;
  }
  #contactus input::placeholder {
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #b3b5b8;
  }
  #contactus label {
    color: #b3b5b8;
    font-size: 18px;
    font-family: "Roboto-Regular";
  }
  #contactus textarea {
    border: 0 !important;
    background: #ebebeb;
    border-radius: 0px !important;
    padding: 16px;
  }
  #contactus textarea::placeholder {
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #b3b5b8;
  }
  #contactus .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
  }
  @media (max-width: 1101px) {
    #contactus label {
      color: #b3b5b8;
      font-size: 16px;
      font-family: "Roboto-Regular";
    }
  }
  @media (max-width:992px){
    section#contactus .contact-info::before {
      top: 29px;
      left: 10px;
  }
  }
  @media (max-width:769px){
    #contactus .form-submit {
      background-size: 100% 100%;
  }
  }