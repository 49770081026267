.forgot-pass-form .form-group input {
    padding: 17px;
    border: 1px solid #244638;
    border-radius: 5px;
    font-weight: 300 !important;
    width: 100%;
  }
  .forgot-pass-form .form-group input::placeholder {
    color: #a5a5a5;
    font-family: 'Poppins-Regular';
    font-weight: 300 !important;
  }
  .forgot-pass-form .form-group input#Email {
    background-image: url(../images/email.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .forgot-pass-form .form-submit,
  .forget-pass-secursity-code-form .form-submit,
  .forgot-pass-newpass .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-weight: 600 !important;
  }
  .forgot-password p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #244638;
  }
  /* forgot password 1 page css start here */
  ul.security-code-fields {
    display: flex;
    list-style-type: none;
    justify-content: center;
    width: 100%;
  }
  ul.security-code-fields li {
    margin: 0 15px;
  }
  .forget-pass-secursity-code-form input {
    padding: 20px !important;
    width: 81px;
    border: 1px solid #24463869;
  }
  .forget-pass-security-code-form-content h2 {
    font-size: 40px;
    font-family: 'Poppins-Regular';
  }
  .forget-pass-security-code-form-content p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  /* forgot password 1 page css ends here */
  /* forgot password 2 page css start here */
  .forgot-pass-newpass .form-group input {
    padding: 17px;
    border: 1px solid #244638;
    border-radius: 5px;
    font-weight: 500 !important;
    width: 100%;
  }
  .forgot-pass-newpass.form-group input::placeholder {
    color: #a5a5a5;
    font-family: 'Poppins-Regular';
    font-weight: 300 !important;
  }
  .forgot-pass-newpass .form-group input#Password {
    background-image: url(../images/padlock.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .forgot-pass-newpass .form-group input#confirm-Password {
    background-image: url(../images/padlock.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .new-password-section-content h2 {
    font-size: 40px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
  }
  .new-password-section-content p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  /* forgot password 2 page css ends here */
  
  @media (max-width: 480px) {
    .forgot-pass-form .form-group input.form-submit {
      margin: 0px !important;
    }
    .forget-pass-secursity-code-form .form-submit {
      margin: 0px;
    }
    .forgot-pass-newpass .form-submit {
      margin: 0px !important;
    }
  }
  @media (max-width: 576px) {
    .forget-pass-secursity-code-form input {
      width: 56px;
    }
  }

  @media (max-width: 480px) {
    .forgot-pass-form .form-submit, .forget-pass-secursity-code-form .form-submit, .forgot-pass-newpass .form-submit {
      display: block;
      width: 50%;
      padding: 20px;
      text-align: initial;
      border: 0px !important;
    }
  }
  