.check-input input {
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    padding: 6px 0px;
  }
  .job-checklist label {
    font-size: 13px;
    color: #000000;
    font-family: "Roboto-Regular";
    margin: 0;
  }
  .job-title-head h2 {
    font-size: 34px;
    color: #303842;
    font-family: "Roboto-Regular";
    font-weight: 600;
    text-transform: uppercase;
  }
  .job-title-head p {
    font-size: 13px;
    color: #707070;
    font-family: "Roboto-Regular";
    font-weight: 400;
  }
  section.job-title {
    padding: 70px 0px;
  }
  .job-title-img img {
    width: 75%;
  }
  .job-title-img {
    text-align: end;
  }
  section.job-checklist .row {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  section.job-title .row {
    display: flex;
    align-items: center;
  }
  .post-price {
    display: flex;
    align-items: center;
    padding-left: 250px;
  }
  .post-job-button {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .post-price-button {
    background-image: url("../images/post-back1.png");
    width: 55%;
    padding: 10px;
    text-align: center;
  }
  .post-price h3 {
    font-size: 20px;
    color: #303842;
    font-family: "Roboto-Regular";
    font-weight: 500;
  }
  .post-price h4 {
    font-size: 40px;
    color: #303842;
    font-family: "Roboto-Regular";
    font-weight: 500;
  }
  .post-price-button a {
    color: #ffffff;
    font-size: 20px;
    font-family: "Roboto-Regular";
    font-weight: 600;
    letter-spacing: 4px;
  }
  section.job-checklist {
    padding: 60px 0px;
  }
  /* Begin of responsive  */
  @media (max-width: 1150px) {
  }
  @media (max-width: 768px) {
    .job-title-head p {
      font-size: 9px;
    }
    section.job-title {
      padding: 30px 0px;
    }
  }
  @media (max-width: 480px) {
    .job-title-img img {
      width: 100%;
    }
    .post-job-button {
      display: unset;
    }
    .post-price {
      padding-left: 0px;
      padding-top: 40px;
    }
    .universal-Banner .heading h1 {
      font-size: 35px;
    }
  }

  @media (max-width: 480px) {
    .universal-Banner .heading h1 {
      font-size: 22px;
    }
  }

  
  /* End of Responsive  */
  