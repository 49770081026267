.Sponsors p {
    color: #8191A6;
    font-size: 15px;
    font-family: 'Roboto-Regular';
}

.Sponsors .sponsor-img img {width: 75%;height: 200px;object-fit: contain;object-position: center;}

.Sponsors .sponsor-img{
    height: 200px;
    width: 100%;
    object-fit: cover;
    text-align: center;
}
.Sponsors .logo-name {
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
}
.Sponsors h2 {
    color: #303842;
    font-size: 34px;
    font-family: 'Montserrat-Regular';
}
.car-sponsor {
    background: url(../images/carsponsor-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.car-sponsor p {
    font-size: 14px;
}
.car-sponsor a {
    color: #fff !important;
    font-size: 30px;
    font-family: 'Montserrat-Regular';
    font-weight: 700;
}
@media (max-width:576px){
    .car-sponsor {
        background-position: left center;
    }
}