.profile_sec .car_informations .form-group .listing-input {
  width: 50%;
}
.hide {
    display: none;
  }
  .show {
    display: block;
  }
  h4 {
    font-family: "Poppins-Regular";
  }
  h5 {
    font-family: "Poppins-Regular";
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  /* Profile Css Start Here */
  .universal-Banner .un-ban {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile_sec {
    padding: 80px 0;
  }
  /* Profile Navs Css Start Here */
  .profile_sec .nav-pills .nav-link {
    text-align: center;
    padding: 12px 0px;
    margin: 5px 40px;
    color: #000;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    cursor: pointer;
  }
  .profile_sec .nav-pills .nav-link.active {
    color: #fff;
    background: #d80909;
  }
  /* Profile Navs Css Start Here */
  
  /* Tabs Content css Start Here */
  .profile_sec .profile_strip {
    display: flex;
    background: #d80909;
    justify-content: space-between;
    padding: 15px 30px;
    border-radius: 5px;
    /* margin-bottom: 30px; */
  }
  .profile_sec .profile_strip h5 {
    margin: 0;
    font-size: 16px;
    color: #f7f7f7;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .profile_strip .edit_btn a {
    color: #ffffff;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    text-decoration: underline;
  }
  /* First tabs Css Start Here */
  .profile_sec .user_details{
    padding: 15px 15px;
  }
  .profile_sec .user_details input{
    padding: 10px 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #d3d0d0;
  }
  .profile_sec .user_details .details {
    margin-bottom: 50px;
  }
  .profile_sec .user_details .details .property {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
  }
  .profile_sec .user_details .details .value {
    color: #d80909;
    font-size: 19px;
    font-weight: 500;
    padding-top: 10px;
  }
  /* First tabs Css End Here */
  
  /* Second Tab Css Start Here */
  .profile_sec .orders_table tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .orders_table tr {
    height: 150px;
  }
  .profile_sec .orders_table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .orders_table tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .orders_table .sno {
    width: 10%;
  }
  .profile_sec .orders_table .product {
    width: 40%;
    border: 0;
    padding-left: 60px;
  }
  .profile_sec .orders_table .price {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .orders_table .order-status {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .orders_table .sno {
    text-align: center;
    width: 10%;
    border: 0;
  }
  .profile_sec .orders_table th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .orders_table td {
    vertical-align: middle;
  }
  .profile_sec .orders_table tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .orders_table tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .orders_table td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .orders_table .product .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .orders_table .product .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .orders_table .product .order_product .order_type {
    margin: 0;
    text-transform: capitalize;
    color: #000000;
    font-weight: 400;
    font-family: 'Poppins-Regular';
    font-size: 11px;
  }
  .profile_sec .orders_table .product .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec .orders_table .product .order_product .order_content .order-name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 2px;
  }
  .profile_sec .orders_table td.price {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .orders_table td.order-status {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  /* Second Tab Css End Here */
  
  /* Third Tab Css Start Here */
  .profile_sec .offers_table tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .offers_table tr {
    height: 150px;
  }
  .profile_sec .offers_table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .offers_table tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .offers_table .sno {
    width: 10%;
  }
  .profile_sec .offers_table .product {
    width: 40%;
    border: 0;
    padding-left: 60px;
  }
  .profile_sec .offers_table .offers {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .offers_table .offer-status {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .offers_table .sno {
    text-align: center;
    width: 10%;
    border: 0;
  }
  .profile_sec .offers_table th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .offers_table td {
    vertical-align: middle;
  }
  .profile_sec .offers_table tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .offers_table tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .offers_table td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .offers_table .product .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .offers_table .product .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .offers_table .product .order_product .offer_type {
    margin: 0;
    text-transform: capitalize;
    color: #000000;
    font-weight: 400;
    font-family: 'Poppins-Regular';
    font-size: 11px;
  }
  .profile_sec .offers_table .product .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec .offers_table .product .order_product .order_content .order-name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 2px;
  }
  .profile_sec .offers_table td.offers {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .offers_table td.offer-status {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  /* Third Tab Css End Here */
  
  /* Fourth Tab Css Start Here */
  .profile_sec ul.colors_cariations {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .profile_sec ul.colors_cariations li {
    display: inline-block;
    margin: 0 2px;
  }
  .profile_sec ul.colors_cariations li input {
    opacity: 0;
  }
  .profile_sec ul.colors_cariations li span.checkmark {
    width: 41px;
    height: 40px;
    display: block;
    margin-top: -10px;
    border: 2px solid #fff;
  }
  .profile_sec ul.colors_cariations li label {
    padding: 0;
  }
  .profile_sec ul.colors_cariations li label span.checkmark:before {
    display: none;
  }
  .profile_sec ul.colors_cariations li input:checked ~ span.checkmark {
    box-shadow: 0px 0px 0px 2px #000;
  }
  .profile_sec .car_informations .title_wrapper {
    padding-bottom: 15px;
    margin-top: 25px;
  }
  .profile_sec .car_informations .title_wrapper h4 {
    font-size: 18px;
    color: #000000;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .car_informations .form-group label {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
  }
  .profile_sec .car_informations .form-group .input_flex {
    display: flex;
  }
  .profile_sec .car_informations .form-group .input_flex .button.btn.paynow {
    background: #d50808;
    color: #fff;
    font-family: "Montserrat-Regular";
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 10px;
    width: 150px;
    margin-left: 10px;
    cursor: pointer;
  }
  .profile_sec .car_informations .form-group .form-control {
    color: #888888;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    border: 1px solid #9b9b9b !important;
    padding: 8px 20px;
    height: 45px;
  }
  .profile_sec .car_informations .form-group .form-control::placeholder {
    color: #888888;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .profile_sec .car_informations .upload_photos_box {
    padding: 20px 0px;
  }
  .profile_sec .car_informations .upload_photos_box .title {
    padding-bottom: 20px;
  }
  .profile_sec .car_informations .upload_photos_box .title h5 {
    font-size: 18px;
    color: #000000;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .car_informations .upload_photos_box .row {
    border: 1px solid #707070;
    padding: 80px 50px;
    margin: 0 0;
  }
  .profile_sec .car_informations .upload_photos_box ul.importants_points {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .profile_sec .car_informations .upload_photos_box .importants_points li {
    list-style: none;
    margin: 15px 0;
    color: #888888;
    font-size: 12px;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .car_informations .upload_photos_box .importants_points li i {
    color: #d80909;
    font-size: 15px;
    padding-right: 5px;
  }
  .profile_sec .car_informations .upload_photos_box .files_flex {
    display: flex;
    align-items: center;
  }
  .profile_sec .car_informations .upload_photos_box .files_flex .custom-file {
    /* text-align: center; */
    padding-left: 20px;
  }
  .profile_sec
    .car_informations
    .upload_photos_box
    .files_flex
    .custom-file
    button {
    background: #d80909;
    border: 0;
    font-size: 15px;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    padding: 11px 35px;
    border-radius: 5px;
  }
  .profile_sec
    .car_informations
    .upload_photos_box
    .files_flex
    input#attachments {
    position: absolute;
    left: 0;
    top: 0;
    height: 45px;
    cursor: pointer;
  }
  .profile_sec .car_informations .upload_photos_box span.note {
    color: #888888;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    padding-left: 13px;
    line-height: 40px;
  }
  .profile_sec .car_informations .upload_photos_box ul#fileList {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .profile_sec .car_informations .upload_photos_box ul#fileList li {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    padding: 5px 15px;
    background: #ddd;
    display: inline-block;
    margin: 2px 4px 2px 0px;
    border-radius: 2px;
    color: #000;
    font-weight: 500;
  }
  .profile_sec .car_informations .upload_photos_box ul#fileList {
    margin-top: 40px;
  }
  .profile_sec
    .car_informations
    .upload_photos_box
    ul#fileList
    li
    span.remove-list {
    padding-left: 12px;
    cursor: pointer;
  }
  .profile_sec .featuresDv {
    padding-top: 40px;
  }
  .profile_sec .featuresDv .title h4 {
    font-size: 18px;
    color: #000000;
  }
  .profile_sec .featuresDv h5 {
    color: #888888;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    padding-bottom: 10px;
    padding-top: 30px;
  }
  .profile_sec .featuresDv .op {
    opacity: 0;
  }
  .profile_sec .featuresDv .form-group {
    margin-bottom: 5px;
  }
  .profile_sec .featuresDv .form-group label {
    padding-left: 5px;
    color: #888888;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    margin: 0;
    font-weight: 400;
  }
  .profile_sec .contact_informations {
    padding-top: 30px;
  }
  .profile_sec .ada_price {
    padding-top: 30px;
  }
  .ada_price .forcheck #checkbx {
    width: 20px;
  }
  .ada_price .forcheck {
    display: flex;
    align-items: center;
  }
  .ada_price .forcheck label {
    margin: 0;
    padding-left: 10px;
  }
  .profile_sec .contact_informations .title_wrapper {
    padding-bottom: 30px;
  }
  .profile_sec .car_informations button.contact_btn {
    width: 100%;
    background: url(../images/contact-btn.png);
    border-radius: 0;
    padding: 13px 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  /* Fourth Tab Css End Here */
  
  /* Fifth Tab Css Start Here */
  .profile_sec .ads_listing tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .ads_listing tr {
    height: 150px;
  }
  .profile_sec .ads_listing tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .ads_listing tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .ads_listing .sno {
    width: 10%;
  }
  .profile_sec .ads_listing .selling {
    width: 50%;
    border: 0;
    padding-left: 60px;
  }
  .profile_sec .ads_listing .chat {
    width: 20%;
    text-align: center;
    border: 0;
  }
  .profile_sec .ads_listing .price {
    width: 20%;
    text-align: center;
    border: 0;
  }
  .profile_sec .ads_listing .sno {
    text-align: center;
    width: 10%;
    border: 0;
  }
  .profile_sec .ads_listing th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .ads_listing td {
    vertical-align: middle;
  }
  .profile_sec .ads_listing tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .ads_listing tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .ads_listing td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .ads_listing .selling .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .ads_listing .selling .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .ads_listing .selling .order_product .order_content .name {
    color: #000000;
    font-size: 16px;
  }
  .profile_sec .ads_listing .selling .order_product .order_content .desc {
    margin-bottom: 5px;
    color: #acacac;
    font-size: 15px;
    font-family: 'Poppins-Regular';
  }
  .profile_sec
    .ads_listing
    .selling
    .order_product
    .order_content
    .actions.btns
    button {
    background: transparent;
    border: 0;
    padding: 0px 0;
    cursor: pointer;
    outline: none;
  }
  .profile_sec
    .ads_listing
    .selling
    .order_product
    .order_content
    .actions.btns
    button.remove_add {
    color: #de3142;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    margin-right: 20px;
  }
  .profile_sec .ads_listing .selling .order_product button.edit {
    background: transparent;
    border: 0;
    padding: 0px 0;
    font-size: 14px;
    color: #a7adb7;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .ads_listing .selling .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec .ads_listing .selling .order_product button.edit {
    background: transparent;
    border: 0;
    padding: 0px 0;
    font-size: 14px;
    color: #a7adb7;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .ads_listing .messages {
    color: #585858;
    font-size: 18px !important;
    border: 1px solid #707070;
    margin: 0 33px;
    padding: 8px 10px;
    border-radius: 5px;
  }
  .profile_sec .ads_listing .price p {
    font-size: 22px;
    color: #585858;
    font-family: 'Poppins-Regular';
    font-weight: 500;
  }
  .profile_sec .ads_listing td.chat p {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .ads_listing td.price {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  
  /* Edit Your Add Css Start Here */
  .edit_your_add .heading_wrapper {
    display: flex;
    background: #d80909;
    justify-content: space-between;
    padding: 15px 30px;
    border-radius: 5px;
    margin-bottom: 30px;
  }
  .edit_your_add .heading_wrapper h5 {
    margin: 0;
    font-size: 16px;
    color: #f7f7f7;
    font-family: 'Poppins-Regular';
  }
  .edit_your_add .avatar-two {
    max-width: 100% !important;
  }
  .profile_sec .edit_your_add .avatar-upload .avatar-edit label {
    margin: 0 130px !important;
  }
  .edit_your_add .avatar-two .avatar-preview {
    border: 0 !important;
  }
  .edit_your_add .avatar-two .avatar-preview.active_img #imagePreview {
    filter: unset;
  }
  .edit_your_add .avatar-edit .images_count {
    height: 100%;
    position: absolute;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 44px;
    font-family: 'Poppins-Regular';
    font-weight: 600;
  }
  .edit_your_add .car_informations {
    padding-top: 40px;
    border-top: 1px solid #70707052;
    margin-top: 40px;
  }
  /* Edit Your Add Css End Here */
  
  /* Fifth Tab Css End Here */
  
  /* Sixth Tab Css Start Here */
  .profile_sec .wishlist_table tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .wishlist_table tr {
    height: 150px;
  }
  .profile_sec .wishlist_table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .wishlist_table tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .wishlist_table .sno {
    width: 10%;
  }
  .profile_sec .wishlist_table .product {
    width: 40%;
    border: 0;
    padding-left: 60px;
  }
  .profile_sec .wishlist_table .chat {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .wishlist_table .add_cart {
    width: 25%;
    text-align: center;
    border: 0;
  }
  .profile_sec .wishlist_table .sno {
    text-align: center;
    width: 10%;
    border: 0;
  }
  .profile_sec .wishlist_table th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .wishlist_table td {
    vertical-align: middle;
  }
  .profile_sec .wishlist_table tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .wishlist_table tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .wishlist_table td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .wishlist_table .product .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .wishlist_table .product .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .wishlist_table .product .order_product .offer_type {
    margin: 0;
    text-transform: capitalize;
    color: #000000;
    font-weight: 400;
    font-family: "Poppins-Regular";
    font-size: 11px;
  }
  .profile_sec .wishlist_table .product .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec
    .wishlist_table
    .product
    .order_product
    .order_content
    .order-name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 2px;
  }
  .profile_sec .wishlist_table td.offers {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .wishlist_table td.add_cart {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .wishlist_table td.add_cart .cart_btn {
    font-size: 14px;
    color: #707070;
    border: 1px solid #707070;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 400;
  }
  /* Sixth Tab Css End Here */
  
  /* Seventh Tab Css Start Here */
  .profile_sec .events_table tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .events_table tr {
    height: 150px;
  }
  .profile_sec .events_table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .events_table tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .events_table .sno {
    width: 10%;
  }
  .profile_sec .events_table .event {
    border: 0;
    padding-left: 30px;
    width: 25%;
  }
  .profile_sec .events_table .chat {
    text-align: center;
    border: 0;
  }
  .profile_sec .events_table .add_cart {
    text-align: center;
    border: 0;
  }
  .profile_sec .events_table .sno {
    text-align: center;
    border: 0;
  }
  .profile_sec .events_table th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .events_table td {
    vertical-align: middle;
  }
  .profile_sec .events_table tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .events_table tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .events_table td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .events_table .event .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .events_table .event .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .events_table .event .order_product .offer_type {
    margin: 0;
    text-transform: capitalize;
    color: #000000;
    font-weight: 400;
    font-family: 'Poppins-Regular';
    font-size: 11px;
  }
  .profile_sec .events_table .event .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec .events_table .event .order_product .order_content .order-name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 2px;
  }
  .profile_sec
    .events_table
    .event
    .order_product
    .order_content
    button.edit_btn {
    padding: 0;
    border: 0;
    background: transparent;
    color: #a7adb7;
    font-family: 'Poppins-Regular';
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
  .profile_sec .events_table td.offers {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .events_table td.view_queries {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .events_table td.view_queries .query_btn {
    font-size: 14px;
    color: #a7adb7;
    border: 1px solid #a7adb7;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: 400;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
  }
  .profile_sec .events_table .date {
    width: 12%;
    text-align: center;
  }
  .profile_sec .events_table td.date {
    color: #000000;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .profile_sec .events_table .time {
    width: 12%;
    text-align: center;
  }
  .profile_sec .events_table td.time {
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: #000000;
    font-weight: 400;
  }
  .profile_sec .events_table .location {
    text-align: center;
    width: 25%;
  }
  .profile_sec .events_table td.location {
    color: #000000;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .events_table .view_queries {
    text-align: center;
    width: 16%;
  }
  /* Edit Events Css Start Here */
  .profile_sec .edit_events_dv {
    padding-top: 30px;
  }
  .profile_sec .edit_events_dv .events_picture h5 {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .profile_sec .edit_events_dv .avatar-upload {
    position: relative;
    max-width: 220px;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-edit label {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 7.5rem;
    left: 0;
    text-align: center;
    background: #d80909;
    margin: 0 20px;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-size: 15px;
    padding: 20px 0;
    line-height: 0;
    border-radius: 5px;
    cursor: pointer;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-edit input {
    display: none;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: "FontAwesome";
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-preview {
    height: 280px;
    position: relative;
    border: 2px solid #7070709e;
    border-radius: 0;
    display: flex;
  }
  .profile_sec .edit_events_dv .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0.5);
  }
  .profile_sec .enter_events_details {
    padding-top: 40px;
  }
  .profile_sec .enter_events_details .form-group label {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
  }
  .profile_sec .enter_events_details .form-group .form-control {
    color: #888888;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    border: 1px solid #9b9b9b;
    padding: 15px 20px;
    line-height: 24px;
  }
  .profile_sec .enter_events_details .form-group textarea {
    height: 140px !important;
    resize: none;
  }
  .profile_sec .enter_events_details button.btn {
    width: 100%;
    background: url(../images/contact-btn.png);
    border-radius: 0;
    padding: 13px 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  /* Edit Events Css Start Here */
  
  /* Events Queries Css Start Here */
  .profile_sec .event_queries tr.topbar {
    height: 60px;
    background: #fff !important;
  }
  .profile_sec .event_queries tr {
    height: 150px;
  }
  .profile_sec .event_queries tr:nth-child(even) {
    background: #f8f8f8;
  }
  .profile_sec .event_queries tr:nth-child(odd) {
    background: #f1f1f1;
  }
  .profile_sec .event_queries .sno {
    width: 10%;
  }
  .profile_sec .event_queries .event {
    border: 0;
    padding-left: 30px;
    width: 25%;
  }
  .profile_sec .event_queries .chat {
    text-align: center;
    border: 0;
  }
  .profile_sec .event_queries .add_cart {
    text-align: center;
    border: 0;
  }
  .profile_sec .event_queries .sno {
    text-align: center;
    border: 0;
  }
  .profile_sec .event_queries th {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .profile_sec .event_queries td {
    vertical-align: middle;
  }
  .profile_sec .event_queries tr:nth-child(odd) td.sno {
    background: #ececec;
  }
  .profile_sec .event_queries tr:nth-child(even) td.sno {
    background: #f1f1f1;
  }
  .profile_sec .event_queries td.sno {
    font-family: 'Poppins-Regular';
  }
  .profile_sec .event_queries .event .order_product {
    display: flex;
    align-items: center;
  }
  .profile_sec .event_queries .event .order_product .img_box img {
    width: 66px;
  }
  .profile_sec .event_queries .event .order_product .offer_type {
    margin: 0;
    text-transform: capitalize;
    color: #000000;
    font-weight: 400;
    font-family: 'Poppins-Regular';
    font-size: 11px;
  }
  .profile_sec .event_queries .event .order_product .order_content {
    padding-left: 20px;
  }
  .profile_sec .event_queries .event .order_product .order_content .order-name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 2px;
  }
  .profile_sec
    .event_queries
    .event
    .order_product
    .order_content
    button.edit_btn {
    background: transparent;
    color: #a7adb7;
    font-family: 'Poppins-Regular';
    outline: none;
    box-shadow: none;
    cursor: pointer;
    font-size: 10px;
    border: 1px solid #a7adb7;
    padding: 4px 8px;
    border-radius: 3px;
  }
  .profile_sec .event_queries td.name {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .event_queries td.view_queries {
    font-size: 14px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .event_queries td.view_queries .total_queries {
    width: 50px;
    text-align: center;
    margin: 0px auto;
    border: 1px solid #a7adb7;
    background: transparent;
    color: #a7adb7;
    font-size: 14px;
    font-family: 'Poppins-Regular';
  }
  .profile_sec .event_queries td.view_queries .query_btn {
    font-size: 14px;
    color: #a7adb7;
    border: 1px solid #a7adb7;
    padding: 10px 10px;
    border-radius: 5px;
    font-weight: 400;
    text-decoration: none;
  }
  .profile_sec .event_queries .name {
    width: 12%;
    text-align: center;
  }
  .profile_sec .event_queries td.name {
    color: #000000;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .profile_sec .event_queries .email {
    width: 12%;
    text-align: center;
  }
  .profile_sec .event_queries td.email {
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: #000000;
    font-weight: 400;
  }
  .profile_sec .event_queries .phone {
    text-align: center;
    width: 15%;
  }
  .profile_sec .event_queries td.phone {
    color: #000000;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .profile_sec .event_queries .view_queries {
    text-align: center;
    width: 16%;
  }
  /* Events Queries Css End Here */
  
  /* About Query Modal Css Start Here */
  .view_about_query_modal .modal {
    background: #000000ad;
  }
  .view_about_query_modal .modal-header {
    border: 0;
    padding: 0;
  }
  .view_about_query_modal .modal-header button.close {
    opacity: 1 !important;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 15px;
    outline: none;
    border: 0;
    box-shadow: none;
  }
  .view_about_query_modal .modal-dialog {
    max-width: 650px !important;
  }
  .view_about_query_modal .modal-dialog .modal-content {
    padding: 30px 40px 20px;
    border-radius: 10px;
    border: 0;
  }
  .view_about_query_modal .modal_title h2 {
    color: #d80909;
    font-family: "Montserrat-Regular";
    font-size: 42px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  .view_about_query_modal .form-group .form-control {
    border: 0;
    border-bottom: 1px solid #b4b4b4;
    border-radius: 0;
    font-size: 15px;
    color: #000;
    font-family: "Roboto-Regular";
    background: transparent;
  }
  .view_about_query_modal .form-group {
    margin-bottom: 30px;
  }
  .view_about_query_modal .form-group textarea {
    height: 110px;
    resize: none;
  }
  /* About Query Modal Css End Here */
  /* Seventh Tab Css End Here */
  
  /* Tabs Content css Start Here */
  
  /* Profile Css End Here */
  
  /* Subscription Css Start here */
  .subscription_plan {
    padding: 10px 0 30px;
  }
  .subscription_plan .subscription_container{
    border: 3px solid #000;
    border-radius: 38px;
  }
  .subscription_plan .heading {
    text-align: center;
    padding-bottom: 40px;
  }
  .subscription_plan .heading p {
    padding: 0 10rem;
  }
  .subscription_plan .subscription_container input {
    opacity: 0;
  }
  .subscription_plan
    .subscription_container
    input:checked
    ~ .checkmark
    .subscription_box {
    border: 3px solid #fff;
    box-shadow: 0px 0px 0px 2px #d50808;
  }
  .subscription_plan
  .subscription_container
  input:checked
  ~ .checkmark
  .subscription_box-N {
}
  .subscription_plan .subscription_box .subscription_name {
    /* background: #d50808; */
    padding: 19px 15px 5px 20px;
    min-height: 70px;
    /* text-align: center; */
  }
  .subscription_plan .subscription_box .subscription_name h4 {
    margin: 0;
    color: #d50808;
    font-size: 22px;
    font-weight: bold;
    font-family: 'HighSpeed';
  }
  .subscription_plan .subscription_box .subscription_name-green {
    /* background: #5c765c ; */
    padding: 19px 15px 5px 20px;
    min-height: 70px;
  }
  .subscription_plan .subscription_box .subscription_name-green h4 {
    margin: 0;
    color: #5c765c;
    font-size: 22px;
    font-weight: bold;
    font-family: 'HighSpeed';
  }
  .subscription_plan .subscription_box .plan_img img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: 0.6s;
  }
  .subscription_plan .subscription_box:hover .plan_img img {
    filter: brightness(0.5);
  }
  .subscription_plan .subscription_box .susbcription_content {
    background: #e7e7e738;
  }
  .subscription_plan .subscription_box .susbcription_content .pricing {
    /* text-align: center; */
    padding: 0px 20px;
    /* border-bottom: 1px solid #ddd; */
     
  }
  .subscription_plan .subscription_box .susbcription_content .description {
    padding: 20px 20px;
    min-height: 375px;
  }
  .subscription_plan .subscription_box .susbcription_content .description p {
    font-size: 13px;
    line-height: 18px;
    text-align: justify;
  }
  .subscription_plan .subscription_box .susbcription_content .pricing h4 {
    margin: 0;
    color: #000;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    font-family: 'HighSpeed';
  }
  .subscription_plan .subscription_box .susbcription_content ul.contentDv {
    list-style: none;
    margin: 0;
    text-align: center;
    padding: 0;
  }
  .subscription_plan .subscription_box .susbcription_content ul.contentDv li {
    border-bottom: 1px solid #ddd;
    padding: 8px 30px;
    font-size: 15px;
  }
  .subscription_plan .subscription_box .susbcription_content ul.contentDv li i {
    color: #08d108;
    margin-right: 10px;
  }
  .subscription_plan
    .subscription_box
    .susbcription_content
    .button-group
    button.red-btn {
      width: 100%;
      border-radius: 0;
      padding: 15px 20px;
      cursor: pointer;
      color: #fff;
      font-family: "Roboto-Regular";
      background: #d50808;
      transition: 0.6s;
  }
  .subscription_plan
  .subscription_box
  .susbcription_content
  .button-group
  button.greenTick {
  width: 100%;
  border-radius: 0;
  padding: 15px 20px;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto-Regular";
  background-color: #5c765c  !important;
  transition: 0.6s;
}
  .subscription_plan
    .subscription_box
    .susbcription_content
    .button-group
    button.btn:hover {
    background: #111111;
  }

  .subscription_plan .button-group{
    padding: 19px 29px;
  }
  /* Subscription Css End here */
  
  @media (max-width: 480px) {
    .profile_sec .car_informations .upload_photos_box ul.importants_points {
      margin-top: 40px;
    }
    .profile_sec .orders_table th {
      font-size: 10px;
    }
  }

  @media (max-width: 375px) {
    .profile_sec .car_informations .upload_photos_box .files_flex .custom-file button {
      font-size: 10px;
    }
    .profile_sec .car_informations .upload_photos_box span.note {
      font-size: 10px;
      padding-left: 0px;
      line-height: 12px;
    }
    .profile_sec .orders_table td.order-status {
      font-size: 12px;
    }
    .profile_sec .orders_table td.price {
      font-size: 12px;
    }
    .profile_sec .orders_table .product .order_product .order_content .order-name {
      font-size: 12px;
  }
  }