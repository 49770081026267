/* Cart Css Start Here */
.table-responsive {
    width: 100%;
    display: table;
}
.table-responsive tbody .pro-detail {
    width: 60%;
}
.cart-sec .quantity-btn {
    padding: 2px 8px;
    background-color: #ff2e3e;
    border: unset;
    color: #fff;
    width: 30px;
    height: 50px;
}
.cart-sec .qty-num {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
}
.cart-sec .quantity-container{
    display: flex;
}
.cart-sec .CartTable table.table-responsive {
    width: 100%;
    display: table;
}
.cart-sec .CartTable table.table-responsive .pro-detail {
    width: 50%;
}
.cart-sec .CartTable table.table-responsive .pro-detail .proFlex {
    display: flex;
    align-items: center;
}
.cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv {
    padding-left: 10px;
}
.cart-sec .CartTable table.table-responsive .price {
    width: 15%;
}
.cart-sec .CartTable table.table-responsive .total {
    width: 15%;
}
.cart-sec {
    padding: 20px 0;
}
.cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv h3.name {
    font-family: 'MYRIADPRO-REGULAR';
    color: #000;
    padding-bottom: 30px;
    font-weight: 600;
}
.cart-sec .CartTable table.table-responsive tr.topBar {
    height: 40px;
    border-bottom: 3px solid #EBEBEB;
}
.cart-sec .CartTable table.table-responsive tr.topBar th {
    font-size: 15px;
    text-transform: uppercase;
    color: #303842;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}
.cart-sec .CartTable table.table-responsive tr {
    height: 220px;
}
.cart-sec .CartTable table.table-responsive tr:nth-child(even) {
    border-bottom: 3px solid #EBEBEB;
}
.cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv .variation p {
    color: #ACACAC;
    font-family: 'MYRIADPRO-REGULAR';
    font-weight: 600;
}
.cart-sec .CartTable table.table-responsive .price span.priceDv {
    color: #ACACAC;
    font-family: 'MYRIADPRO-REGULAR';
    font-weight: 600;
    margin-right: 8px;
}
.cart-sec .CartTable table.table-responsive .total {
    text-align: right;
}
.cart-sec .CartTable table.table-responsive .total span.totalPrice {
    color: #2B2B2B;
    font-weight: 700;
}
.cart-sec .CartTable table.table-responsive .total .actionBtn {
    padding-top: 50px;
}
.cart-sec .CartTable table.table-responsive .total .actionBtn a.edit {
    color: #ACACAC;
    font-family: 'Poppins-Regular';
    font-weight: 600;
}
.cart-sec .CartTable table.table-responsive .total .actionBtn a.save {
    color: #ACACAC;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    font-size: 15px;
}
.cart-sec .CartTable table.table-responsive td.price.vr {
    vertical-align: top;
    padding-top: 40px;
}
.cart-sec .orderSummary h4 {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
}
.cart-sec .orderSummary {
    background: #EEEEEE;
    padding: 20px 20px;
}
.cart-sec .orderSummary .head {
    padding-bottom: 20px;
}
.cart-sec .orderSummary .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.cart-sec .orderSummary .form-group label {
    font-size: 14px;
    color: #303842;
    font-family: 'Roboto-Regular';
}
.cart-sec .orderSummary .form-group .form-control {
    border-radius: 0;
    border: 1px solid #707070;
    font-size: 13px;
    color: #323232;
    font-family: 'Roboto-Regular';
}
.cart-sec .orderSummary .totalDv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 10px;
}
.cart-sec .orderSummary .button-group a.btn {
    width: 100%;
    background: #FF2E3E;
    color: #fff;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 20px;
    font-weight: 600;
    border-radius: 0;
}
.cart-sec .orderSummary .button-group {
    margin-bottom: 15px;
}
.cart-sec .orderSummary .promotionDv {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.cart-sec .orderSummary .promotionDv input{
    padding: 6px 10px;
    margin-top: 13px;
}

.cart-sec .orderSummary .promotionDv .property h4 {
    font-size: 16px;
    margin-bottom: 0;
}

.cart-sec .applyCode-btn{
    background-color: #303842;
    padding: 9px 16px;
    color: #fff;
    margin-top: 10px;
    width: 100%;
}
/* Cart Css End Here */

@media (max-width: 1100px) {
    .cart-sec .orderSummary .button-group a.btn {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv h3.name {
        padding-bottom: 12px;
    }
}

@media (max-width: 480px) {
    .cart-sec .CartTable {
        overflow-x: scroll;
    }
    .cart-sec .qty-num {
        margin-left: 6px;
        margin-right: 6px;
        font-size: 12px;
    }
    .cart-sec .CartTable table.table-responsive tr.topBar th {
        font-size: 10px;
    }
    .cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv h3.name {
        padding-bottom: 5px;
        font-size: 20px;
    }
    .cart-sec .CartTable table.table-responsive .pro-detail .proFlex .contentDv .variation{
        font-size: 12px;
    }
}


