/* font-cdn  */
@import url('http://fonts.cdnfonts.com/css/myriad-pro');
/* font-cdn */
/* Begin of button section   */
.to-button button {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins-Regular';
}
section.total-btn {
    padding: 50px 0px;
}
.to-button button {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    border-radius: 30px;
    padding: 14px 2px;
    width: 90%;
}
.to-button button:hover {
    background: #D11E22;
}
/* End of button section */

/* Dashboard css  */
.car-info {
    display: flex;
}
.dash-order {
    display: flex;
    justify-content: space-between;
}
.color-dash {
    display: flex;
    justify-content: space-between;
}
.product-code {
    display: flex;
    justify-content: space-between;
}
.dash-order h2 {
    font-size: 17px;
}
.recent-order p {
    font-size: 16px;
    color: #ACACAC;
    font-family: 'MYRIADPRO-REGULAR';
    margin: 0;
}
.for-space {
    padding: 30px 0px;
}
.dashboard-head h2 {
    font-size: 34px;
    color: #303842;
    font-family: 'Montserrat-Regular';
    text-transform: uppercase;
    text-align: center;
}
.dashboard-head {
    padding: 30px 0px;
}
/* End of dashboard css */

/* Begin of progress bar section  */
  .textbox {
    height: 700px;
  }
  
  .progress {
    margin-bottom: 10px;
  }
  
  .progress-bar {
    width: 0;
    background-color: #D50808
  }
  .progess-bars label {
    font-size: 11px;
    color: #000000;
    font-weight: 600;
    margin: 0;
}
.customer-size-stars ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.customer-size-stars ul li a {
    color: #FFB800;
}
.customer-size-stars-para p {
    font-size: 11px;
    color: #000;
    font-family: 'Poppins-Regular';
    margin: 0;
}
.customer-size h3 {
    font-size: 18px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
}
.customer-size-stars {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.to-button {
    margin-bottom: 50px;
}
.customer-size-stars ul li a i {
    margin-right: 12px;
}
.recent-order p {
    font-size: 16px;
    color: #ACACAC;
    font-family: 'MYRIADPRO-REGULAR';
    margin: 0;
    font-weight: 400;
}
.see-all {
    float: right;
}
.see-all a {
    font-size: 11px;
    color: #0E62B7;
    font-family: 'Poppins-Regular';
    font-weight: 600;
}
/* End of progress bar section  */

/* Begin of seller dashboard section  */
.dash-jd-name h3 {
    font-size: 14px;
    color: #2A2A2A;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    margin: 0;
}
.dash-jd-name p {
    font-size: 10px;
    color: #2A2A2A;
    margin: 0;
}

.jd-img img {
   width: 70px;
   height: 70px;
   overflow: hidden;
}
.dash-jd-name h5 {
    font-size: 11px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 600;
    margin: 0;
}
.dash-jd-stars ul li a i {
    font-size: 10px;
    margin-right: 5px;
}
.jd-main-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dash-heading h2 {
    font-size: 25px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 400;
    margin: 0;
}
.dash-heading p {
    font-size: 9px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 300;
}
.dashboard-payment-head h3 {
    font-size: 18px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
}
.total-amount-anchor a {
    font-size: 10px;
    color: #0E62B7;
    font-family: 'Poppins-Regular';
    font-weight: 700;
}
.total-amount h5 {
    font-size: 15px;
    color: #2A2A2A;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}
.total-amount h2 {
    font-size: 35px;
    color: #2A2A2A;
    font-family: 'Poppins-Regular';
    font-weight: 600;
}
.total-amount-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.dashboard-payment-head {
    padding-top: 40px;
}
.payment-sum {
    background-color: #000000;
    text-align: center;
    padding: 30px;
    border-radius: 30px;
    margin-top: 22px;
    transition: 0.8s;
}
.payment-sum h3 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Poppins-Regular';
    font-weight: 500;
}
.payment-sum h4 {
    font-size: 26px;
    color: #ffffff;
    font-family: 'Poppins-Regular';
    font-weight: 600;
}
section.dasboard-sec {
    padding-top: 30px;
    padding-bottom: 80px;
}
.payment-sum:hover {
    background: #d50808;
}
/* End of seller dashboard section  */

/* Begin of responsive  */
@media (max-width:1150px){
    .payment-sum {
        padding: 30px 30px;
    }
    .payment-sum h3 {
        font-size: 13px;

    }
    .payment-sum h4 {
        font-size: 18px;
    }
    .dash-heading h2 {
        font-size: 23px;
    }
    .to-button button {
        font-size: 12px;
    }
    .dash-jd-name h5 {
        font-size: 8px;
    }
    .dash-heading p {
        font-size: 9px;
    }
}
@media (max-width:768px){
    .dash-heading h2 {
        font-size: 16px;
    }
    .dash-jd-name h3 {
        font-size: 8px;
    }
    .customer-size-stars {
        display: unset;
    }
    .total-amount h5 {
        font-size: 10px;
    }
    .total-amount h2 {
        font-size: 18px;
    }
    .total-amount-anchor a {
        font-size: 6px;
    }
    .to-button button {
        font-size: 9px;
    }
    .payment-sum {
        padding: 20px 30px;
    }
    .dashboard-head {
        padding: 20px 0px;
    }
    .customer-size h3 {
        font-size: 16px;
    }
    
}
@media (max-width:480px){
    .payment-sum {
        padding: 10px 16px;
        margin-bottom: 20px;
    }
    .dash-order {
        padding-top: 14px;
    }
}

/* End of responsive  */