.car-video p,
.sponsec p,
.contact-seller-content p {
  color: #8191a6;
  font-size: 15px;
  font-family: "Roboto-Regular";
}
.car-video h2,
.contact-seller-content h2 {
  color: #303842;
  font-size: 34px;
  font-family: 'Montserrat-Regular';
}
.sponsec h2 {
  color: #303842;
  font-size: 34px;
  font-family: "HighSpeed";
}
.seller-car-sec .img-box .ii {
  filter: brightness(0.5);
}
.lear-btn .btn {
  background-image: url(../images/back.png);
  background-size: 100%;
  padding: 17px 81px;
  border: 0px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  color: #fff !important;
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 600;
}
/* video 1 page css start here */
.black-side-sec-heading {
  display: flex;
  justify-content: space-between;
}
.seller-bg {
  background-image: url("../images/sellerbg.png");
}
.seller-bg {
  background-image: url(../images/sellerbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 51px;
}

.seller-car-sec .heading {
  text-align: center;
  padding-bottom: 40px;
}
.seller-car-sec .video-box .img-box figure img {
  width: 100%;
  height: 191px;
}
.seller-car-sec .video-box {
  position: relative;
}
.seller-car-sec .video-box .plyBtn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.seller-car-sec .video-box .plyBtn.tiny figure img {
  width: 30px;
}
.seller-car-sec .video-box .plyBtn.small figure img {
  width: 60px;
}
.seller-car-sec .video-box .videoTitle {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
}
.seller-car-sec .video-box.one .videoTitle h3 {
  font-size: 27px;
  color: #fff;
}
.seller-car-sec .video-box .videoTitle h3 {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}
.seller-car-sec .video-box.one .img-box figure img {
  height: 445px;
  object-fit: cover;
}
.seller-car-sec .video-box.same .img-box figure img {
  height: 500px;
  object-fit: cover;
}
.seller-car-sec .col-lg-3 {
  padding: 0px !important;
}
.black-side-sec-heading .sellercarprice h3 {
  color: #ff2e3e !important;
}
.black-side-sec-para p {
  font-size: 14px;
  font-family: "Roboto-Regular";
}
.seller-car-sec small .readmore {
  color: #fff !important;
}
.seller-info .seller-number {
  color: #303842;
  font-size: 24px;
  font-family: 'Montserrat-Regular';
  font-weight: 600;
}
.seller-contact-form .form-submit {
  display: block;
  background: url("../images/contactformsubmit.png");
  background-repeat: no-repeat;
  background-position: right;
  width: 89%;
  padding: 14px;
  border: 0px !important;
  color: #fff;
}
.seller-contact-form input {
  border: 0 !important;
  background: #ebebeb;
  border-radius: 0px !important;
  padding: 16px;
}
.seller-contact-form input::placeholder {
  font-family: "Roboto-Regular";
  font-size: 18px;
  color: #b3b5b8;
}
.seller-contact-form label {
  color: #b3b5b8;
  font-size: 18px;
  font-family: "Roboto-Regular";
}
.seller-contact-form textarea {
  border: 0 !important;
  background: #ebebeb;
  border-radius: 0px !important;
  padding: 16px;
}
.seller-contact-form textarea::placeholder {
  font-family: "Roboto-Regular";
  font-size: 18px;
  color: #b3b5b8;
}
/* video 1 page css end here */
@media (max-width: 1100px) {
  .seller-bg {
    background: #000 !important;
  }
  .seller-car-sec .video-box .img-box figure img {
    height: 241px;
    object-fit: cover;
  }
}
@media (max-width: 769px) {
  .seller-info {
    text-align: center;
    padding-bottom: 50px;
  }
}
