/* Font Family */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.cdnfonts.com/css/myriad-pro?styles=949,946,952");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
@import url("https://fonts.cdnfonts.com/css/stretch-pro?styles=83502");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "highspeed";
  src: url(../fonts//highspeed.otf);
}
@font-face {
  font-family: "neutro";
  src: url(../fonts/neutro.otf);
}
@font-face {
  font-family: "gadugi";
  src: url(../fonts/gadugi/gadugi-bold.ttf);
}
@font-face {
  font-family: "a4speed";
  src: url(../fonts/a4/a4speed.otf);
} */
/* Font Family */

/* Chrome, Safari, Edge, Opera */



.video_bottom_text{
    /* padding: 10px 0 20px; */
    font-family: "A4SPEED-Bold";
    text-transform: uppercase;
    font-size: 40px;
    line-height: 42px;
    text-align: center;
    margin: 0;
    color: #000;
}

/* Menufacture Sec Css Start Here */
.manufacture {
  border: 4px solid #000;
  border-radius: 15px;
  padding: 1px 25px 0;
}
.manufacture .brand_img .ferrari {
  /* height: 90px; */
  height: 60px;
  width: 100%;
}
.manufacture .brand_img .ferrari-sm {
  /* height: 90px; */
  height: 60px;
  width: 100%;
}
.manufacture .brand_img .ferrari-gold {
  /* height: 80px; */
}
.manufacture {
  border: 14px solid #000;
  border-radius: 48px;
  padding: 10px;
}
.manufacture .brands {
  display: flex;
  align-items: center;
}
.manufacture .brands .brand_img {
  flex: 1;
}
.manufacture .heading h3 {
  font-size: 62px;
  font-weight: 600;
  color: #000;
  font-family: 'Impact';
  font-style: italic;
}
.manufacture .button-group button.form-submit {
  display: block;
  background: url(../images//back.png);
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  padding: 11px 45px 11px 10px;
  border: 0px;
  color: #fff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
}
/* Menufacture Sec Css End Here */

.cs-map path:hover {
  background-color: #637cce !important;
}
.rsm-geography:focus {
  border: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat";
}
p {
  font-family: "Roboto-Regular";
}
h2 {
  font-size: 38px;
  font-weight: 600;
}
h3 {
  font-size: 30px;
  font-weight: 600;
}
figure {
  margin-bottom: 0;
}
/* Header css Start Here */
/* link button hover */
a:hover {
  text-decoration: none !important;
}
/* link button hover */

.main-header a#navbarDropdown {
  padding: 0px;
}

/* Brands Page Content Css */
.showcase .content h2 {
  font-family: "HighSpeed";
}
/* Brands Page Content Css */

/* Secondary Header css Start Here */
header .secondaryHeader {
  background: linear-gradient(57deg, black, #00000087);
  padding: 10px 0;
}
/* .secondaryHeader .active {
  border-bottom: 4px solid #fff;
} */
header .secondaryHeader ul.secondary-items {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}
header .secondaryHeader ul.secondary-items li {
  display: inline-block;
}
header .secondaryHeader ul.secondary-items li a {
  font-family: HighSpeed;
  text-decoration: none;
  color: #000;
}
header .secondaryHeader ul.secondary-items li a i {
  color: #000;
  font-size: 25px;
}
header .secondaryHeader ul.secondary-items li a.downloads {
  opacity: 0.6;
}
header .secondaryHeader ul.secondary-items li:last-child {
  margin-right: 0;
}
/* Secondary header Css End Here */

/* Main header css Start Here */
header .main-header {
  background: #000;
}
header .main-header .logoDv {
  margin-top: -30px;
}
header .navbarMenu {
  display: flex;
  justify-content: flex-end;
}
header .navbarMenu ul.navItems {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .navbarMenu ul.navItems li.menu-items {
  display: inline-block;
  padding-right: 13px;
}
header .navbarMenu ul.navItems li.menu-items:last-child {
  padding-right: 0px;
}
header .navbarMenu ul.navItems li.menu-items.btn {
  padding-left: 0;
  padding-right: 0;
}
header .navbarMenu ul.navItems li.menu-items.btn a {
  padding: 10px 15px;
  background: #d50808;
  font-family: "Montserrat-Regular";
  border-radius: 4px;
}
header .navbarMenu ul.navItems li.menu-items a {
  font-family: "Montserrat-Regular";
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 14px;
}
header .navbarMenu ul.navItems li.menu-items a.active {
  border-bottom: 3px solid #c4000a;
}
/* Main Header Css End here */
/* mobile header start here */
header .mobile-header {
  width: 30%;
  height: 100%;
  position: fixed;
  background: #000;
  top: 0;
  z-index: 1;
  right: 0;
  padding-top: 30px;
  transition: 0.7s;
  transform: translateX(100%);
  overflow-y: scroll;
}
header .mobile-header.show {
  transform: translateX(0%);
}
header .mobile-header ul.mobile-nav {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
header .mobile-header .cancel {
  background: #fff;
  width: 35px;
  /* text-align: center; */
  height: 35px;
  line-height: 55px;
  margin: 0px 20px 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .mobile-header ul.mobile-nav li.nav-item {
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid #dddddd4a;
}
header .mobile-header ul.mobile-nav li.nav-item a {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
}
header .mobile-header .secondary-items {
  list-style-type: none;
  padding: 0px !important;
}
header .mobile-header .secondary-items li {
  padding: 5px 22px !important;
  line-height: 33px;
  border-bottom: 1px solid;
}
.mobile-header ul li a {
  font-family: "Montserrat-Regular";
  font-size: 17px !important;
  color: #fff !important;
}
.canvas-icon.text-white {
  display: none;
}
.navbarMenu .canvas-icon i.fa.fa-bars {
  font-size: 27px;
}
/* mobile header end here */
/* header responsive queries start here*/
@media (max-width: 1198px) {
  .canvas-icon.text-white {
    display: block;
  }
  .secondaryHeader {
    display: none;
  }
  header .main-header .logoDv {
    margin: 0px !important;
  }
}
@media (max-width: 1198px) {
  header ul.navItems {
    display: none;
  }
}
@media (max-width: 576px) {
  header .mobile-header {
    width: 50%;
  }
}

/* header responsive queries start here*/
/* Header css End here */

/* Universal Banner Css Start Here */
.universal-Banner {
  background-image: url(../images/cart-banner.png);
  height: 200px;
  display: flex;
  align-items: center;
}
.universal-Banner .heading h1 {
  font-size: 48px;
  font-weight: 600;
  font-family: Poppins-Regular;
}
/* Universal Banner Css End Here */

/* Banner Css Start Here */
.Slidebanner .banner .img-box figure img {
  width: 100%;
  position: relative;
}
.Slidebanner .banner .ply-btn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: none;
}
.Slidebanner .banner .title h2 {
  font-family: HighSpeed;
  color: #fff;
  position: relative;
  margin-left: -40px;
}
.Slidebanner .banner .title {
  position: absolute;
  bottom: 50px;
  right: 0;
  border-bottom: 2px solid #fff;
  width: 500px;
  opacity: 0;
}
.video-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-wrapper iframe{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video-wrapper iframe:hover{
  pointer-events: none;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 575px) {
  .Slidebanner .banner .ply-btn figure img {
    width: 60%;
    margin: 0px auto;
  }
}
/* Banner Css End Here */

/* Brands Css Start Here */
.brandSlides {
  padding: 15px 35px;
  box-shadow: 0px 0px 10px #ccc;
}
.brandSlides .slick-track:before {
  display: table;
  content: "\2190";
}
.brandSlides .slick-track:after {
  display: table;
  content: "\2192";
}
.brandSlides .slick-track {
  display: flex;
  align-items: center;
}
.brandSlides .slick-track {
  width: 100% !important;
}
.brandSlides .brand-box figure img {
  margin: 0px auto;
  object-fit: contain;
  width: 42px !important;
}
/* responsive quries */
@media (max-width: 992px) {
  .Slidebanner .banner .title h2 {
    font-size: 29px;
    margin-left: 86px !important;
  }
}
@media (max-width: 886px) {
  .Slidebanner .banner .title h2 {
    font-size: 29px;
    margin-left: 166px !important;
  }
}
@media (max-width: 768px) {
  .Slidebanner .banner .title {
    display: none;
  }
}
@media (max-width: 576px) {
  .Slidebanner .banner .title {
    border: none !important;
    display: none;
  }
  button.slick-next.slick-arrow {
    display: none !important;
  }
}
/* Brands Css End Here */

/* find your dream section start here */
.dreamcar h2 {
  color: #d80909;
  font-family: "Neutro-ExtraBold";
  font-size: 32px !important;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
}
.showcase-listing ul {
  padding: 0px !important;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.showcase-listing a.showcase-list {
  font-size: 16px;
  color: #000;
  font-family: HighSpeed;
  font-weight: 900;
}
/* .showcase-listing ul li {
  display: inline-block;
  text-align: center;
  width: 19%;
} */
.showcase-listing ul {
  padding: 0px !important;
  list-style-type: none;
  width: 100% !important;
}
.showcase-listing ul li {
  margin-right: 5px;
  width: 100%;
}
.showcase-listing ul .find_box .img_box img {
  height: auto !important;
  object-fit: cover;
  /* border: 2px solid #707070; */
  border-bottom: 0;
  width: 100%;
}
.showcase-listing ul .find_box .contentDv {
  border: 2px solid #707070;
  padding: 20px 0;
}
.showcase-listing a.showcase-list img.img-fluid {
  height: 130px;
}
/* .showcase-listing ul li {
  display: flex;
  text-align: center;
  width: 100%;
  background: #fff !important;
  height: 59px;
  align-items: center;
  justify-content: center;
  border: 2px solid #333333;
} */
.car-search-form .form-submit {
  display: block;
  background: url("../images/contactformsubmit.png");
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  padding: 7px 45px 11px 0px;
  border: 0px;
  color: #fff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.car-search-form select.form-control {
  border: 1px solid #707070 !important;
  padding: 13px;
  border-radius: 5px;
  height: 50px !important;
}
.car-search-form input::placeholder {
  color: #000000;
  font-size: 18px;
  font-family: "Montserrat-Regular";
  font-weight: 500;
}
/* .showcase-listing ul li:hover {
  background: #d80909 !important;
} */
@media (max-width: 991px) {
  .dreamcar h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .showcase-listing a.showcase-list {
    font-size: 11px;
  }
  .car-search-form input {
    margin-bottom: 5px;
  }
  .car-search-form .form-submit {
    background-position: center;
  }
}
@media (max-width: 575px) {
  .dreamcar h2 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
  .showcase-listing ul {
    display: block;
  }
  .showcase-listing ul li {
    margin-bottom: 5px;
  }
  .dreamcar .showcase-listing {
    padding-top: 20px !important;
  }
  .dreamcar .car-search-form {
    padding-top: 10px !important;
  }
}
/* find your dream section End here */

/* SHowCase Css Start Here */
.showcase {
  padding: 20px 0 80px;
}
.showcase .head {
  padding-bottom: 50px;
}
.showcase .carType .heading {
  background-image: url(../images/back.png);
  text-align: center;
  color: #fff;
  background-size: 100%;
  padding: 12px 0;
  background-repeat: no-repeat;
}
.showcase .carType .heading h3 {
  margin-bottom: 0;
  width: 90%;
}
.showcase .head h2.title {
  padding-bottom: 0px;
  /* text-shadow: 7px 7px #ffffff; */
  text-align: center;
  font-family: "Neutro-ExtraBold";
  text-transform: uppercase;
  font-style: italic;
  font-size: 72px;
  margin-left: -50px;
  margin-right: -50px;
  color: #d80909;
  position: relative;
}
.showcase .head h2.title:before {
  content: "SHOWCASE LISTINGS";
  position: absolute;
  color: #000;
  z-index: -1;
  bottom: -7px;
  left: 5px;
  /* left: 16px; */
}
.showcase .carType ul.chooseCar {
  padding: 15px 0;
  margin: 5px 0;
  list-style: none;
  background: #1b1b20;
}
.showcase .carType ul.chooseCar li {
  font-size: 25px;
  font-family: "Montserrat-Regular";
  font-weight: 500;
  padding: 10px 40px;
  transition: 0.6s;
}
.showcase .carType ul.chooseCar li:hover {
  background: linear-gradient(54deg, #ffffff, #b93535);
}
.showcase .carType ul.chooseCar li a {
  color: #fff;
  text-decoration: none;
  transition: 0.6s;
}
.showcase .carType ul.chooseCar li:hover a {
  color: #000;
}
.showcase .carBox {
  margin-bottom: 10px;
}
.showcase .carBox .img-box figure img {
  width: 100%;
  object-fit: cover;
  height: 208px;
}
.showcase .carBox .BottomBorder {
  border-bottom: 5px solid #d60808;
}
.showcase .carBox .contentDv {
  background: #000;
  padding: 15px 0px;
}
.showcase .carBox .contentDv h5 {
  font-size: 15px;
  color: #fff;
  font-family: "Montserrat-Regular";
  font-weight: 700;
}
.showcase .carBox .contentDv h5 {
  padding-left: 10px;
}
.showcase .carBox .contentDv h6 {
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  font-family: "Montserrat-Regular";
}
.showcase .carBox .contentDv h6 {
  padding-left: 10px;
}
.showcase .carBox .contentDv .price {
  background: #d80909;
  padding: 1px 30px;
  width: 110px;
  float: right;
  margin-top: 5px;
}
.showcase .carBox .contentDv .price span {
  color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 12px;
  font-weight: 700;
}
.showcase .carBox .contentDv .extras {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  padding: 0 10px;
}
.showcase .carBox .contentDv .extras .types span {
  color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 10px;
  padding-left: 5px;
}
.showcase .action-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.showcase .action-btn button {
  border-radius: 0;
  background: #ff2e3e;
  border: 0;
  color: #fff;
  font-family: "Montserrat-Regular";
  font-weight: 600;
  padding: 6px 40px;
  cursor: pointer;
}
@media (max-width: 575px) {
  .showcase {
    padding: 10px 0 60px;
  }
}
@media (max-width: 450px) {
  .showcase .head h2.title {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 0;
  }
}
/* ShowCase Css End Here */

/* Customize Banner Css Start here */
.customizeBanner {
  background-image: url(../images/customize.png);
  height: 420px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.customizeBanner .content h2 {
  font-family: "HighSpeed";
  font-size: 50px;
  line-height: 60px;
  color: #d80909;
}
.customizeBanner .content h4 {
  padding: 10px 0 20px;
  font-family: "A4SPEED-Bold";
  text-transform: uppercase;
  font-size: 27px;
  line-height: 37px;
}
/* .customizeBanner .content p {
  font-family: "Roboto-Regular";
  font-weight: 100;
} */
.customizeBanner .content a {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  font-weight: 500;
}
@media (max-width: 991px) {
  .customizeBanner .content h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 575px) {
  .customizeBanner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../images/customize.png);
    height: 340px;
    background-size: cover;
    background-position: right;
  }
  .customizeBanner .content h4 {
    font-size: 18px;
  }
}
/* Customize Banner Css End here */

/* Month pick css Start here */
.monthPick {
  padding: 60px 0;
  background-image: url(../images/monthback.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.monthPick .heading {
  text-align: center;
  padding-bottom: 40px;
}
.monthPick .heading h2 {
  text-transform: uppercase;
  font-family: "Neutro-ExtraBold";
  font-style: italic;
  padding-bottom: 20px;
  color: #000;
}
.monthPick .heading h2 span.redish {
  color: #d80909;
}
.monthPick .heading h4 {
  font-size: 22px;
  line-height: 30px;
  font-family: "gadugi";
  font-weight: bold;
  color: #000;
  text-shadow: 0 0 black;
}
.monthPick .carShow .img-box {
  margin-bottom: 30px;
}
.monthPick .carShow .img-box figure img {
  width: 100%;
  height: 170px;
  /* object-fit: contain; */
}
.monthPick .carShow .contentDv h4 {
  font-size: 20px;
  font-weight: 600;
  background: #d80909;
  width: 72%;
  position: relative;
  color: #fff;
  padding: 3px 10px;
}
.monthPick .carShow .contentDv h4:after {
  content: "";
  background: #000;
  width: 10px;
  height: 100%;
  border-top: 27px solid #f2f2f2;
  border-left: 27px solid #d80909;
  position: absolute;
  right: 0;
  bottom: 0;
}
.monthPick .carShow .contentDv p {
  padding-right: 15%;
  font-weight: 500;
  color: #292b2c;
  margin: 0;
}
.monthPick .carShow .contentDv .price {
  background: #d80909;
  padding: 5px 15px;
  width: 40%;
  position: relative;
  margin-top: 8px;
}
.monthPick .carShow .contentDv .price p {
  margin-bottom: 0;
  color: #fff;
  font-family: "Montserrat-Regular";
  font-weight: 300;
}
.monthPick .carShow .contentDv .price:after {
  content: "";
  background: #000;
  width: 10px;
  height: 100%;
  border-top: 33px solid #f2f2f2;
  border-left: 27px solid #d80909;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1024px) {
  .showcase .head h2.title {
    margin-right: unset;
  }
}
@media (max-width: 991px) {
  .monthPick .carShow .contentDv h4 {
    font-size: 15px;
    width: 100%;
  }
  .monthPick .carShow .contentDv h4:after {
    border-top: 23px solid #f2f2f2;
  }
  .monthPick .carShow .contentDv .price {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .monthPick .heading h2 {
    font-size: 25px;
  }
}
@media (max-width: 450px) {
  .monthPick .carShow .contentDv h4:after {
    display: none;
  }
  .monthPick .carShow .contentDv .price:after {
    display: none;
  }
}
/* Month pick css End here */

/* Ribbon Section Start Here */
.ribbon {
  background-image: url(../images/ribbonback.png);
  padding: 60px 0 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-y: hidden;
}
.ribbon .heading {
  text-align: left;
  padding-bottom: 50px;
}
.ribbon .heading h2 {
  font-size: 40px;
  font-family: "Neutro-ExtraBold";
  line-height: 50px;
  font-style: italic;
  padding-bottom: 10px;
}
.ribbon .heading h2 span.redish {
  color: #d80909;
}
.ribbon .heading h3 {
  font-family: "Neutro-ExtraBold";
  font-size: 26px;
  line-height: 35px;
  font-style: italic;
  padding-bottom: 20px;
}
.ribbon .heading h3 span.redish {
  color: #d80909;
}
.ribbon .heading .button-group {
  text-align: end;
}
.ribbon .heading .button-group a.btn.t-shirt-btn {
  color: #ffffff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  font-weight: 600;
  padding: 0;
}
.ribbon .merchandise.topp .title {
  background: #d80909;
  padding: 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78%;
  margin: 0 0 0 -7px;
  transform: skewX(-10deg);
}
.ribbon .merchandise.btm .title {
  background: #d80909;
  padding: 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78%;
  margin: 0 -8px 0 auto;
  transform: skewX(-10deg);
}
.ribbon .merchandise .title a {
  color: #fff;
}
.ribbon .merchandise .title h4 {
  font-size: 26px;
  font-weight: 600;
  font-family: "Montserrat-Regular";
  text-align: center;
}
.ribbon .merchandise .img-box {
  position: relative;
  z-index: 1;
}
.ribbon .merchandise.btm .img-box:before {
  content: "";
  background: #d80909;
  width: 90px;
  height: 50px;
  position: absolute;
  bottom: -10px;
  right: 40px;
  transform: skewX(-10deg);
  z-index: -1;
}
.ribbon .merchandise.topp .img-box:before {
  content: "";
  background: #d80909;
  width: 90px;
  height: 50px;
  position: absolute;
  top: -10px;
  left: 40px;
  transform: skewX(-10deg);
  z-index: -1;
}
.ribbon .merchandise .img-box figure img {
  width: 100%;
}
@media (max-width: 991px) {
  .ribbon .merchandise .title h4 {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .ribbon {
    background-color: #000;
    background-image: none;
  }
  .ribbon .merchandise.btm .img-box:before {
    display: none;
  }
  .ribbon .merchandise.topp .img-box:before {
    display: none;
  }
  .ribbon .merchandise {
    margin: 20px 100px 0 80px;
  }
}
@media (max-width: 450px) {
  .ribbon {
    padding-bottom: 30px;
  }
  .ribbon .heading h2 {
    font-size: 25px;
  }
  .ribbon .merchandise {
    margin: 20px 40px 0 20px;
  }
}
/* Ribbon Section End Here */

/* GarageTee section start here */
.GarageTee {
  background: #000;
  padding: 0px 0px !important;
}
.GarageTee .GarageTee-content {
  padding-right: 120px;
}
.GarageTee h5 {
  color: #ffff;
  padding: 5px 0;
}
.GarageTee .button-group {
  padding: 13px 0;
}
.GarageTee .button-group a {
  color: #fff;
}
.GarageTee p {
  color: #fff;
  font-family: "Roboto-Regular";
  line-height: 30px;
  padding: 20px 0 10px;
}
/* .GarageTee-content h2 {
  font-family: "highspeed";
  font-size: 40px;
  font-weight: 400;
}
.GarageTee-content h1 {
  color: #d80909;
  font-size: 57px;
  font-family: "Montserrat";
} */
.GarageTee-content button {
  background: transparent;
  color: #fff;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  background-size: contain;
  font-size: 24px;
  font-family: "Montserrat-Regular";
  cursor: pointer;
}
@media (max-width: 991px) {
  .GarageTee img.img-fluid {
    margin: 30px auto 0px auto;
    display: table;
  }
}
@media (max-width: 575px) {
  .GarageTee-content h2 {
    font-size: 30px;
  }
  .GarageTee-content h1 {
    font-size: 35px;
  }
}
/* GarageTee section end here */

/* Active car Show Css Start Here */
.activeShow {
  padding: 60px 0 40px;
}
.activeShow .heading {
  text-align: center;
  padding-bottom: 40px;
}
.activeShow .heading h2 {
  font-family: "A4SPEED-Bold";
  font-size: 50px;
  padding-bottom: 20px;
  color: black;
}
.activeShow .heading h4 {
  color: #202020;
  font-size: 22px;
  padding: 0 90px;
  line-height: 30px;
}
.activeShow .heading p {
  font-size: 14px;
  font-family: "Roboto-Regular";
  padding: 0 10%;
}
.activeShow .activecars {
  padding: 0 60px;
}
.activeShow .activecars .img-box figure {
  height: 390px;
  width: 300px;
  margin: 0px auto;
}
.activeShow .activecars .img-box figure img {
  height: 390px;
  width: 300px;
  margin: 0px auto;
}
.activeShow .slick-list{
  background-color: black;
  padding: 13px 0px;
}
.activeShow .activecars button.slick-prev.slick-arrow::before {
  content: "\f060";
  font-family: "FontAwesome";
  font-size: 25px;
  color: #d80909;
  border: 3px solid #000;
  padding: 8px 10px;
  border-radius: 50px;
  position: absolute;
  left: -40px;
  opacity: 1 !important;
}
.activeShow .activecars button.slick-next.slick-arrow::before {
  content: "\f061";
  font-family: "FontAwesome";
  font-size: 25px;
  color: #d80909;
  border: 3px solid #000;
  padding: 8px 10px;
  border-radius: 50px;
  position: absolute;
  right: -40px;
  opacity: 1;
}
.activeShow .button-group {
  padding-top: 70px;
  text-align: center;
}
.activeShow .button-group a.form-submit {
  background: url(../images/contactformsubmit.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 15px 105px 16px 40px;
  border: 0px;
  color: #fff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .activeShow .activecars .img-box figure img {
    width: 258px
  }
}
@media (max-width: 991px) {
  .activeShow .activecars .img-box figure img {
    height: 390px;
    width: 180px;
  }
  .activeShow .activecars .img-box figure {
    height: 390px;
    width: 180px;
}
}
@media (max-width: 575px) {
  .activeShow {
    padding: 60px 0 20px;
  }
  .activeShow .activecars button.slick-arrow {
    display: none !important;
  }
  .activeShow .activecars {
    padding: 0 10px;
  }
  .activeShow .activecars .img-box figure img {
    padding: 0 10px;
    height: auto;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .carsVideos .heading h2 {
    font-size: 35px;
}
  .activeShow .heading h2 {
    font-size: 30px;
  }
  .activeShow .activecars .img-box figure {
    height: 200px;
    width: 126px;
}
  .activeShow .activecars .img-box figure img {
    height: 200px;
    width: 126px;
    padding: 0 12px;
  }
  .activeShow .heading h4 {
    padding: 0 35px;
  }
  .activeShow .button-group a.form-submit {
    padding: 10px 116px 10px 12px;
    font-size: 18px;
  }
}
/* Active car Show Css End Here */

/* Cars Video Css Start Here */
.carsVideos {
  padding: 0px 0;
}

.carsVideos .plate-container{
  width: 200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.carsVideos .plate-container img{
  width: 100%;
}

.single-video{
    padding-top: 65px;
}

.carsVideos .heading {
  text-align: center;
  padding-bottom: 40px;
}
.carsVideos .linktag{
  display: flex;
    justify-content: center;
    align-items: center;
}
.carsVideos .linktag .img-cont{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.carsVideos .linktag .img-cont img{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.carsVideos .heading h2 {
  text-transform: uppercase;
  font-size: 51px;
  font-family: "A4SPEED-Bold";
  color: black;
}
.carsVideos .video-box .img-box figure img {
  width: 100%;
  border-right: 8px solid #d80909;
}
.carsVideos .video-box {
  position: relative;
  margin-bottom: 15px;
}
.carsVideos .video-box .plyBtn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.carsVideos .video-box .plyBtn.tiny figure img {
  width: 30px;
}
.carsVideos .video-box .plyBtn.small figure img {
  width: 60px;
}
.carsVideos .video-box .videoTitle {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
}
.carsVideos .video-box.one .videoTitle h3 {
  font-size: 16px;
  color: #fff;
}
.carsVideos .video-box .videoTitle h3 {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat-Regular";
}
.carsVideos .video-box.one .img-box figure img {
  height: 445px;
  object-fit: cover;
}
.carsVideos .video-box.same .img-box figure img {
  height: 500px;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .car-search-form .form-submit {
    padding: 14px 64px 15px 0px;
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .carsVideos .car-list {
    display: flex;
  }
  .carsVideos .video-box.same .img-box figure img {
    height: 300px;
  }
}
@media (max-width: 575px) {
  .carsVideos .car-list {
    display: block;
  }
  .carsVideos .video-box.one .img-box figure img {
    height: auto;
  }
  .carsVideos .video-box.same .img-box figure img {
    height: auto;
  }
}
@media (max-width: 450px) {
  .carsVideos .video-box.one .videoTitle h3 {
    font-size: 20px;
  }
}
/* Cars Video Css End Here */

/* Blogs Css Start Here */
.blogs .img-box figure img {
  width: 100%;
}
.blogs .head {
  padding-bottom: 40px;
  text-align: center;
}
.blogs .post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.blogs .post .image-box figure img {
  width: 100%;
}
.blogs .post .content {
  padding-left: 20px;
}
.blogs .post .content .title p {
  font-size: 16px;
  font-weight: 500;
  color: #303842;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
}
.blogs .post .content .description {
  padding: 3px 0;
}
.blogs .post .content .info p {
  margin-bottom: 0;
}
.blogs .post .content .info {
  display: flex;
  align-items: center;
  padding-top: 11px;
}
.blogs .post .content .info .admin {
  width: 120px;
}
.blogs .post .content .info .admin p {
  font-weight: 500;
}
.blogs .post .content .info .date span {
  font-weight: 400;
  font-family: "Roboto-Regular";
  font-size: 13px;
}
@media (max-width: 575px) {
  .blogs {
    padding-bottom: 60px;
  }
}
@media (max-width: 450px) {
  .blogs .post {
    display: block;
  }
  .blogs .post .image-box {
    margin-bottom: 10px;
  }
  .blogs .post .content {
    padding: 0;
  }
}
/* Blogs Css End Here */

/* Footer Css Start Here */
footer {
  background-color: #000;
  padding: 40px 0;
}
footer .txt p {
  font-size: 14px;
  padding-top: 8px;
  /* height: 152px; */
  /* display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
footer .txt .links {
  padding-bottom: 10px;
}
footer .txt .links a {
  color: #303842;
  font-size: 12px;
  margin-right: 20px;
  font-weight: 700;
  text-decoration: none;
}
footer .txt p.copyright {
  margin-bottom: 0;
  color: #8191a6;
  font-weight: 500;
  font-family: "Roboto-Regular";
  opacity: 0.6;
  font-size: 14px;
  height: unset;
}
footer .head h5 {
  font-size: 14px;
  font-family: "Roboto-Regular";
  margin-bottom: 30px;
}
footer .op h5 {
  opacity: 0;
}
footer ul.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
footer ul.footer-menu li {
  margin-bottom: 5px;
}
footer ul.footer-menu li a {
  font-size: 14px;
  line-height: 30px !important;
  text-decoration: none;
  font-family: "Roboto-Regular";
  font-weight: 500;
  color: #a8acb9;
}
footer ul.footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer ul.footer-social li {
  display: inline-block;
  margin: 0 10px;
}
footer ul.footer-social li a i {
  color: #fff;
}
@media (max-width: 991px) {
  footer ul.footer-social li {
    margin: 0 5px;
  }
}
@media (max-width: 768px) {
  footer .head h5 {
    padding-top: 20px;
  }
}
@media (max-width: 480px) {
  .courtesy-cont{
    width: 100%;
    overflow: hidden;
  }
  
  .courtesy-cont img{
    width: 100%;
  }
  .dreamcar {
    padding-top: 14px !important;
  }
  .video-wrapper {
    height: auto;
  }
  .table-responsive {
    display: block;
  }
  .showcase .head h2.title:before {
    content: "SHOWCASE LISTINGS";
    position: absolute;
    color: #000;
    z-index: -1;
    bottom: -5px;
    left: 0px;
    /* left: 16px; */
  }
  .customizeBanner .content h4 {
    line-height: 22px;
  }
  .brandSlides {
    padding: 15px 35px;
    box-shadow: 0px 0px 10px #ccc;
    display: none;
  }
  .monthPick .heading h4 {
    font-size: 14px;
  }
  .monthPick .carShow .contentDv .price {
    margin-bottom: 20px;
  }
  .activeShow .button-group {
    padding-top: 45px;
  }
  .carsVideos {
    padding: 22px 0;
  }
  .dreamcar h2 {
    font-size: 22px !important;
  }

  .car-search-form select {
    margin-bottom: 10px;
  }
  .manufacture .heading h3 {
    font-size: 46px;
  }


}
/* Footer Css Start Here */

/* Preloader */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should have another color then white */
  z-index: 99;
  /* makes sure it stays on top */
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  /* centers the loading animation horizontally one the screen */
  top: 50%;
  /* centers the loading animation vertically one the screen */
  background-image: url(https://raw.githubusercontent.com/niklausgerber/PreLoadMe/master/img/status.gif);
  /* path to your loading animation */
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
  /* is width and height divided by two */
}

@media (max-width: 375px) {
  .manufacture .heading h3 {
    font-size: 40px;
  }
  footer .txt p {
    padding-top: 6px;
  }
}

@media (max-width: 320px) {
  .manufacture .heading h3 {
    font-size: 34px;
  }
  .monthPick .heading h2 {
    font-size: 24px;
  }
  .showcase .head h2.title:before {
    bottom: -4px;
}
}

