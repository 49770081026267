.profile h3 {
    font-size: 22px;
    color: #d00808;
    font-weight: 600;
    font-family: 'Poppins-Regular';
    width: 200px;
  }
  .profile h5 {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    padding-left: 0px;
    font-family: 'Poppins-Regular';
  }
  .profile a {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-left: 0px;
  }
  .profile {
    display: flex;
    align-items: baseline;
    padding: 8px 0px;
  }
  .proflie-btn a {
    display: block;
    background: url(../images/contactformsubmit.png);
    background-repeat: no-repeat;
    background-position: right;
    width: 80%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-family: 'Poppins-Regular';
  }
  section.profile-sec {
    padding: 80px 0px;
  }
  .profile h5 span {
    color: #d80909;
    font-size: 13px;
    font-family: 'Poppins-Regular';
    font-style: italic;
    padding-left: 22px;
  }
  .un-ban {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .edit-profile-btn a {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #000000;
    font-weight: 400;
    float: right;
    font-style: italic;
  }
  .sell-car-img2 {
    margin-bottom: 5px;
  }
  .seller-btn a {
    display: block;
    background: url(../images/probtn.png);
    background-repeat: no-repeat;
    background-position: inherit;
    width: 100%;
    padding: 16px 160px;
    border: 0px !important;
    color: #fff;
    font-weight: 700;
    text-align: inherit;
    font-family: 'Poppins-Regular';
    font-size: 25px;
    background-size: 100% 100%;
  }
  .sell-car-img1 img {
    position: relative;
    width: 100%;
    padding: 0px;
    height: 450px;
  }
  .seller-btn {
    position: absolute;
    bottom: 98px;
    /* padding: 0px 211px; */
  }
  .seller-btn h4 {
    font-size: 48px;
    color: #ffffff;
    font-family: 'Poppins-Regular';
    padding-left: 15px;
    font-weight: 600;
  }
  .sellmycar-sec .sellmycar-head {
    padding-bottom: 20px;
  }
  .sellmycar-sec .sellmycar-head h2 {
    font-family: 'Poppins-Regular';
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    font-family: 'Poppins-Regular';
  }
  .sellmycar-sec .sell-car-img2 {
    position: relative;
  }
  .sellmycar-sec .sell-car-img2 .btnBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #34343487;
  }
  .sellmycar-sec .sell-car-img2 .btnBox a.btnn {
    color: #ffffff;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
  }
  section.sellmycar-sec {
    /* padding: 70px 0px; */
    padding-bottom: 30px;
  }
  /* Begin o Responsive */
  @media (max-width: 1150px) {
    .profile h3 {
      font-size: 16px;
    }
    .profile h5 {
      font-size: 13px;
    }
  }
  @media (max-width: 768px) {
    .profile h5 {
      font-size: 11px;
    }
    .profile h3 {
      font-size: 9px;
    }
    .profile a {
      font-size: 11px;
    }
    .proflie-btn a {
      padding: 7px;
      font-size: 10px;
    }
    .seller-btn h4 {
      font-size: 32px;
    }
  }
  @media (max-width: 480px) {
    .profile h5 {
      font-size: 12px;
      padding-left: 33px;
    }
    .profile a {
      font-size: 12px;
      padding-left: 46px;
    }
    .profile h3 {
      font-size: 14px;
    }
    .universal-Banner .heading h1 {
      font-size: 24px;
    }
    .sell-car-img1 img {
      margin: 16px 0px;
    }
    .seller-btn h4 {
      font-size: 20px;
    }
  }
  /* End of responsive */
  