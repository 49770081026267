/* Begin of button section   */
.to-button button {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins-Regular';
}
section.total-btn {
    padding: 50px 0px;
}
.to-button button {
    background-color: #000;
    color: #fff;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    border-radius: 30px;
    padding: 20px 46px;
    width: 90%;
}
.to-button button:hover {
    background: #D11E22;
}
/* End of button section */

/* Begin of return history section  */
.returns-history-head {
    background-color: #707070;
}
.returns-history-head h2 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Poppins-Regular';
    padding: 10px;
    font-weight: 500;
    margin: 0;
}
.return-history-text h3 {
    font-size: 20px;
    font-family: 'Poppins-Regular';
    color: #D20808;
    font-weight: 600;
}
.return-history-text p {
    font-size: 18px;
    color: #BCBEC0;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}
.return-history-text h5 {
    font-size: 20px;
    font-family: 'Poppins-Regular';
    color: #D20808;
    font-weight: 600;
    text-align: end;
}
.return-history-text {
    /* background: #d6d6d642; */
    padding: 12px;
}
section.returns-history-sec .for-padding {
    padding: 0;
}
.return-img {
    padding-top: 4px;
}
section.returns-history-sec {
    padding: 40px 0px;
}
section.returns-history-sec .for-bottom-line {
    border-bottom: 1px solid #707070;
    border-top: 1px solid #707070;
}
/* End of return history section  */

/* Garage page css  */
.g-no-line {
    border-bottom: none !important;
}

/* End of garage page css  */

/* Begin of responsive  */
@media (max-width:1150px){
    
}
@media (max-width:768px){
    .to-button button {
       
        padding: 20px 0px;
        width: 100%;
    }
}
@media (max-width:480px){
    .to-button {
        padding: 10px;
    }
    section.returns-history-sec .for-bottom-line {
        border-bottom: none;
        border-top: none;
    }
    .return-img {
        text-align: center;
    }
    .universal-Banner .heading h1 {
        font-size: 38px;
    }
}

/* End of responsive  */