/* Chat Css Start Here */
.universal-Banner .un-ban {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chart_sec {
    padding: 80px 0;
  }
  .chart_sec .top-bar {
    display: unset;
    align-items: unset;
    justify-content: unset; 
  }
  .chart_sec .title_strip {
    background: #d50808;
    padding: 17px 30px;
    border-radius: 5px;
  }
  .chart_sec .title_strip h5 {
    margin: 0;
    font-size: 15px;
    color: #fff;
    font-family: 'Poppins-Regular';
  }
  .chart_sec .chattings {
    padding: 60px 0 0;
  }
  .chart_sec .chattings .sidebar .top-bar .user {
    display: flex;
    align-items: center;
    padding: 0px 10px 20px;
  }
  .chart_sec .chattings .sidebar .top-bar .user .img_box img {
    /* width: 33px; */
    margin-bottom: 0;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
  .chart_sec .chattings .sidebar .top-bar .user .img_box {
    margin-right: 10px;
  }
  .chart_sec .chattings .sidebar .top-bar .user .name h4 {
    font-size: 14px;
    color: #182034;
    font-family: 'Poppins-Regular';
    font-weight: 600;
  }
  .chart_sec .chattings .sidebar .search_box {
    padding: 0 10px;
  }
  .chart_sec .chattings .sidebar .search_box .form-group {
    display: flex;
    position: relative;
    align-items: center;
  }
  .chart_sec .chattings .sidebar .search_box .form-group .form-control {
    border: 1px solid #dbdbdb;
    padding: 8px 10px;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-family: 'Poppins-Regular';
  }
  .chart_sec
    .chattings
    .sidebar
    .search_box
    .form-group
    .form-control::placeholder {
    font-size: 11px;
    color: #cbcaca;
    font-family: 'Poppins-Regular';
    font-style: italic;
  }
  .chart_sec .chattings .sidebar .search_box .form-group button.btn {
    position: absolute;
    right: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }
  .chart_sec .chattings .sidebar .filter-option .form-control {
    background: transparent;
    border: 0;
    padding: 0 10px;
    color: #bebebe;
    font-size: 10px;
    font-family: 'Poppins-Regular';
  }
  .chart_sec .chattings .sidebar .onlines_users {
    height: 600px;
    overflow-y: scroll;
    padding: 10px 10px;
  }
  .chart_sec .chattings .sidebar .onlines_users::-webkit-scrollbar {
    width: 2px;
  }
  .chart_sec .chattings .sidebar .onlines_users::-webkit-scrollbar-track {
    background: #ccc;
  }
  .chart_sec .chattings .sidebar .onlines_users::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  .chart_sec .chattings .sidebar .onlines_users .online_chats {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 0px 1px #ddd;
    padding: 15px 15px;
    margin-bottom: 10px;
  }

  .chart_sec .chattings .sidebar .onlines_users .online_chats figure img{
    margin-bottom: 0;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
  .chart_sec .chattings .sidebar .onlines_users .online_chats .name h4 {
    color: #182034;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 700;
    margin: 0px 0 3px 0;
  }
  .chart_sec .chattings .sidebar .onlines_users .online_chats .name p {
    font-size: 10px;
    color: #8990a1;
    margin: 0;
  }
  .chart_sec .chattings .sidebar .onlines_users .online_chats .contentDv {
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
  }
  .chart_sec .chattings .sidebar .onlines_users .online_chats .contentDv .date p {
    margin-bottom: 0;
    font-size: 10px;
    color: #8990a1;
  }
  /* .chart_sec .chattings .sidebar .onlines_users .online_chats .img_box img {
    width: 42px;
  } */
  .chart_sec .chattings .chating_user_detail .online_chats {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 20px;
  }
  .chart_sec .chattings .chating_user_detail .online_chats p {
    margin: 0;
  }
  .chart_sec .chattings .chating_user_detail .online_chats .img_box img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  .chart_sec .chattings .chating_user_detail .online_chats .name {
    padding-left: 20px;
  }
  .chart_sec .chattings .chating_user_detail .online_chats .name h4.user_name {
    margin: 0;
    font-size: 20px;
    color: #182034;
    font-weight: 600;
    font-family: 'Poppins-Regular';
  }
  .chart_sec .chattings .chating_user_detail .online_chats .name p.status {
    color: #8990a1;
    font-size: 11px;
    padding-top: 5px;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .chattings .messages {
    margin: 30px 0;
    height: 600px;
    overflow-y: auto;
  }
  .chattings .messages::-webkit-scrollbar {
    width: 0px;
  }
  .chattings .messages .chatting {
    margin-bottom: 30px;
  }
  .chattings .messages .chatting .message_box .sms {
    background: #f1f1f1;
    padding: 25px 20px;
    font-size: 13px;
    color: #6a6f7c;
    font-family: 'Poppins-Regular';
    border-radius: 5px;
  }
  .chattings .messages .chatting .message_box .sms-red {
    background: #b35b5b;
    padding: 25px 20px;
    font-size: 13px;
    color: #fff;
    font-family: 'Poppins-Regular';
    border-radius: 5px;
  }
  .chattings .messages .your_message .sms {
    background: #c3d9e12b !important;
  }
  .chattings .messages .chatting .message_box .date {
    font-size: 12px;
    color: #8990a1;
    font-family: 'Poppins-Regular';
    font-weight: 400;
  }
  .chattings .messages .other_message {
    margin: 0px auto 30px 0;
    display: table;
  }
  .chattings .messages .your_message {
    margin: 0px 0 12px auto;
    display: table;
  }
  .chart_sec .chattings .chating_user_detail .bottom_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #f3f2f1 !important;
  }
  .chart_sec .chattings .chating_user_detail .bottom_bar .form-group {
    margin: 0;
    flex: 1;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .form-group
    .form-control {
      background: transparent;
    /* background: #f8f8f8; */
    border: 0;
    padding: 15px 20px;
    border-radius: 0;
    color: #000;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    width: 100%;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .form-group
    .form-control::placeholder {
    font-size: 13px;
    color: #8990a1;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .button-group
    button.btn.send_btn
    i {
    color: #d80909;
    font-size: 22px;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .button-group
    button.btn.send_btn {
    /* background: #fff; */
    background: transparent;
    cursor: pointer;
    height: 100%;
  }
  .chart_sec .chattings .chating_user_detail .bottom_bar .button-group button {
    margin: 0 5px;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .button-group
    button:last-child {
    margin: 0;
  }
  .chart_sec
    .chattings
    .chating_user_detail
    .bottom_bar
    .button-group
    button.btn.more_option {
    background: #d80909;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    .chart_sec .chattings .sidebar .search_box .form-group .form-control {
      width: 100%;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats {
      padding: 8px 8px;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats figure img {
      height: 35px;
      width: 35px;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats .contentDv {
      display: block;
    }
  }
  @media (max-width: 575px) {
    .universal-Banner .un-ban .banner-img {
      /* display: none; */
    }
    .chart_sec .chattings .sidebar .onlines_users {
      height: 190px;
      padding: 10px 10px;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats {
      box-shadow: 0px 0px 0px 2px #ddd;
    }
    .chart_sec .chating_user_detail {
      padding-top: 60px;
    }
    .chart_sec .chattings .chating_user_detail .bottom_bar {
      position: unset;
    }
  }

  @media (max-width: 480px) {
    .chart_sec .chattings .sidebar .onlines_users {
      height: 255px;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats figure img {
      height: 40px;
      width: 40px;
    }
    .chart_sec .chattings .sidebar .onlines_users .online_chats .contentDv {
      display: flex;
    }
  }
  /* Chat Css End Here */
  