/* GarageTee section start here */
.GarageTee {
    background: #000;
  }
  .GarageTee .GarageTee-content {
    padding-right: 0px;
  }
  .GarageTee-content h2 {
    font-family: 'HighSpeed';
    font-size: 40px;
    font-weight: 400;
  }
  .GarageTee-content h1 {
    color: #00bcd4;
    font-size: 43px;
    font-family: "Montserrat-Regular";
  }
    .GarageTee-content button {
    /* background: url(../images/tshirtbtn.png); */
    color: #fff;
    padding: 20px 45px;
    background-repeat: no-repeat;
    text-align: center;
    background-position: center;
    background-size: contain;
    font-size: 24px;
    font-family: "Montserrat-Regular";
  }
  /* GarageTee section end here */
  /* tshirt-club start here */
  .tshirt-club h2 {
    font-size: 40px;
    color: #000;
  }
  .tshirt-club input {
    border: 0 !important;
    background: #ebebeb;
    border-radius: 0px !important;
    padding: 16px;
  }
  .tshirt-club input::placeholder {
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #b3b5b8;
  }
  .tshirt-club label {
    color: #b3b5b8;
    font-size: 18px;
    font-family: "Roboto-Regular";
  }
  .tshirt-club textarea {
    border: 0 !important;
    background: #ebebeb;
    border-radius: 0px !important;
    padding: 16px;
  }
  .tshirt-club textarea::placeholder {
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #b3b5b8;
  }
  .tshirt-club .form-group.row {
    padding: 10px 0px;
  }
  .tshirt-club .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-weight: 500;
    font-family: "Roboto-Regular";
    cursor: pointer;
  }
  /* tshirt-club start here */
  