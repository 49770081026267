.carlist .manufacture {
  display: none;
}
.carlist .distanceDv {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Listing Css Start Here */
.dreamcar h2 {
  font-size: 24px;
  /* padding-bottom: 20px; */
}
.showcase-listing ul .find_box .img_box img {
  height: 100px;
}
.showcase-listing a.showcase-list img.img-fluid {
  height: 90px;
}
.showcase .head h2.title {
  font-size: 68px;
}
.showcase .head h2.title:before {
  /* bottom: -5px;
    left: 255px;
    font-size: 72px; */
}

.carProduct .head h2.title:before {
  bottom: -5px !important;
  left: 15px !important;
}

.carProduct .head h2.title {
  font-size: 48px;
}
/* Listing Css Start Here */

/* Side Filters Css Start Here */
.new-searchform .form-group {
  width: 100%;
}
.new-searchform .spacer {
  margin: 0 2px;
}
.new-searchform .form-group label {
  font-size: 12px;
  font-family: "Poppins-Regular";
  margin-bottom: 5px;
}
.new-searchform .form-group .form-control {
  width: 100%;
  background: #ffffff;
  border: 0;
  height: 40px !important;
  border-radius: 0;
  font-family: "Poppins-Regular";
  font-size: 13px;
  color: #1b1b20;
  font-weight: 500;
  padding: 0px 10px;
}
.new-searchform .form-group ::placeholder {
  padding: 10px 10px;
}
.new-searchform .form-group .form-control option {
  text-align: left;
}
.new-searchform .form-group .w-110 {
  width: 110px;
}
.new-searchform .title h4 {
  font-size: 16px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 5px;
}
.custom_radios {
  color: #ffffff;
  font-size: 13px;
  font-family: "Poppins-Regular";
  padding-left: 25px;
  position: relative;
}
.custom_radios input {
  opacity: 0;
}
.custom_radios span.checkmark:before {
  content: "";
  background: transparent;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 2px;
  border: 2px solid #1b1b20;
  box-shadow: 0px 0px 0px 1px #b2b2b2;
}
.custom_radios input:checked ~ span.checkmark:before {
  background: #fff;
}
.new-searchform .anchor_btn {
  background: #2b2b31;
  text-align: center;
  padding: 7px 10px 9px;
  margin: 15px 0;
  line-height: 15px;
}
.new-searchform .anchor_btn a.links {
  color: #ffffff;
  font-size: 11px;
  text-decoration: underline;
  font-family: "Poppins-Regular";
  font-weight: 500;
}
.exteriro_color ul.colors_cariations {
  margin: 0;
  padding: 0;
  list-style: none;
}
.exteriro_color ul.colors_cariations li {
  display: inline-block;
  margin: 0 2px;
}
.exteriro_color ul.colors_cariations li span.checkmark {
  width: 41px;
  height: 40px;
  display: block;
  margin-top: -10px;
  border: 2px solid #000;
}
.exteriro_color ul.colors_cariations li label {
  padding: 0;
}
.exteriro_color ul.colors_cariations li label span.checkmark:before {
  display: none;
}
.exteriro_color ul.colors_cariations li input:checked ~ span.checkmark {
  box-shadow: 0px 0px 0px 2px #f5f5dc;
}
.new-searchform .card {
  background: transparent;
  border: 0;
}
.new-searchform .card .card-header {
  padding: 0;
  background: transparent;
  border: 0;
}
.new-searchform .card button.btn.btn-link {
  padding: 0;
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  border: 0;
}
.new-searchform .card button.btn.btn-link[aria-expanded="true"] i:before {
  content: "\f0d8 ";
}
.new-searchform .card button.btn.btn-link h5 {
  font-size: 13px;
  font-family: "Poppins-Regular";
  font-weight: 600;
  margin: 0;
}
.new-searchform .card .card-body {
  padding-top: 10px;
}
.featureDv .card {
  margin: 10px 0;
}
.new-searchform .button-group {
  margin-top: 25px;
}
.new-searchform .button-group button.btn {
  width: 100%;
  background: #d10808;
  color: #ffffff;
  font-family: "Poppins-Regular";
  font-size: 15px;
  padding: 12px 0;
  cursor: pointer;
}
/* Side Filters Css Start Here */

/* new-searchform css start*/
.new-searchform {
  background: #1b1b20;
  padding: 30px;
  border-radius: 2px;
  height: 77rem;
  overflow: auto;
}
.new-searchform::-webkit-scrollbar {
  width: 2px;
}
.new-searchform::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.new-searchform h3 {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
}
ul#carsearch .nav-link {
  background: none;
  border: 0px !important;
  color: #ff2e3e !important;
  font-family: "Montserrat-Regular";
  padding: 0px !important;
}
ul#carsearch .nav-link.active {
  background: none;
  border: 0px !important;
  color: #ff2e3e !important;
  font-family: "Montserrat-Regular";
}
ul#carsearch {
  border-bottom: none !important;
}
.new-searchform img.reddots {
  position: absolute;
  right: 29px;
  transform: translateY(-30px);
}
.new-searchform input {
  border-radius: 0px !important;
  color: #000 !important;
  border: 2px solid #cfcfcf;
}
.new-searchform .color-vari {
  border: 0;
  background: transparent;
  padding: 0;
  height: 40px;
}
.new-searchform input::placeholder {
  color: #000 !important;
  font-size: 15px;
}
.new-searchform .years {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.new-searchform label {
  color: #fff;
  margin-bottom: 0px;
}
.new-searchform .Engine {
  display: flex !important;
  align-items: center;
}
.new-searchform .Engine input {
  flex: 1;
}
.new-searchform .Engine label {
  flex: 1;
}
.new-searchform button.form-submit {
  color: #fff !important;
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-weight: 600;
  background-image: url("../images/submit.png");
  border: 0px !important;
  background-color: transparent;
  padding: 19px 89px 19px 26px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: 7px;
}
/* new-searchform css end*/
/* filter section css start*/
.filterform {
  padding: 30px !important;
  background: #1b1b20;
}
.filterform h3 {
  border-bottom: 1px solid;
  font-size: 21px;
  font-weight: 600;
  padding-bottom: 10px;
}
.filterform .transbuttons {
  display: flex;
  justify-content: space-between;
}
.filterform .transbuttons button.transbtn {
  width: 93px;
  height: 42px;
  border-radius: 0px !important;
  font-family: "Montserrat-Regular";
  border: 0px !important;
  font-weight: 500;
}
.filterform .recent-listing ul {
  list-style-image: url("../images/bulletsdot.svg");
  line-height: 20px;
}

/**
   * Range Sliders
   */
.range-sliders {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}
.range-sliders input[type="range"] {
  width: 100%;
  background: linear-gradient(to right, #f00 0%, #f00 50%, #fff 50%, #fff 100%);
  border-radius: 0px;
  height: 8px;
  outline: none;
  border: 1px solid #cfcfcf;
  -webkit-appearance: none;
}

.range-sliders input[type="range"]:focus {
  outline: none;
}

.range-sliders input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background-color: #e6e6e6;
  border-radius: 0;
}

.range-sliders input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background-color: #ccc;
  border-radius: 0;
}

.range-sliders input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  height: 30px;
  width: 12px;
  border-color: transparent;
  background-color: #999;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -15px;
}

.range-sliders input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  height: 30px;
  width: 12px;
  border-color: transparent;
  background-color: #999;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -15px;
}

.range-sliders input[type="range"]::-moz-focus-outer {
  border: 0;
}

.range-sliders input[type="range"].range-slider-red {
  background: #fff;
}

.range-sliders
  input[type="range"].range-slider-red::-webkit-slider-runnable-track {
  background-color: rgba(255, 0, 0, 0.1);
}

.range-sliders input[type="range"].range-slider-red::-moz-range-track {
  background-color: rgba(255, 0, 0, 0.1);
}

.range-sliders input[type="range"].range-slider-red::-webkit-slider-thumb {
  background: url("../images/range.svg");
  background-size: 100% 100%;
  width: 10px;
  height: 31px;
}
.range-sliders .range-slider-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.filterform h6.pri {
  margin-bottom: 20px;
}

.range-sliders .range-slider-group .range-slider {
  width: 100%;
}

.range-sliders .range-slider-group .input-slider {
  width: 45px;
}

/* filter section css end*/
/* car listing tabs */
ul#Buycartabs {
  border: 0px !important;
}
.nav-tabs .nav-link {
  border: none !important;
}
ul#Buycartabs .nav-link.active {
  color: #ff2e3e;
}
ul#Buycartabs .nav-link {
  font-size: 19px;
  font-weight: 500;
  color: #acacac;
  font-family: "MYRIADPRO-REGULAR";
  letter-spacing: 1px;
  padding: 0 15px;
}
ul#Buycartabs li.nav-item:nth-child(1) {
  border-right: 1px solid #acacac;
}
ul#Buycartabs li.nav-item:nth-child(2) {
  border-right: 1px solid #acacac;
}
.top-bar .tabs-links a.nav-link {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.carlist .cart-btn button.btn {
  background: #ff2e3e;
  border-radius: 0px !important;
  color: #fff;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  font-weight: 700;
}
.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
figure.proimg {
  transition: all 0.3s ease-in-out;
}
figure:hover .image {
  filter: brightness(0.5);
}

figure .middle {
  opacity: 1;
}
figure:hover .middle {
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
figure .middle {
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
figure .text {
  color: white !important;
  font-size: 16px;
  padding: 16px 32px;
}
figure img.car-img {
  height: 213px;
  object-fit: cover;
}
figure .middle .text {
  font-size: 25px !important;
  line-height: 0px;
  padding: 0px;
  text-transform: uppercase;
  font-family: "MYRIADPRO-REGULAR";
}
.car-content .carnamenprice {
  display: flex;
}
.car-content h3 {
  font-size: 25px;
}
.car-content h3 {
  font-size: 25px;
  font-family: "MYRIADPRO-REGULAR";
  font-weight: bold;
}
.car-content h3.carprice {
  color: #ff2e3e;
  margin-left: 40px;
}
.car-content h5 {
  font-size: 19px;
  font-family: "MYRIADPRO-REGULAR";
  color: #acacac;
}
.car-content p.cardescription {
  font-size: 14px;
  color: #707070;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.car-content .instantOfferTag {
  background-color: #acacac;
  color: #fff;
  font-size: 12px;
  padding: 6px 10px;
  margin: 0;
  border-radius: 6px;
}
.car-content .instantOfferTag p {
  font-size: 10px;
}
.ratings ul {
  display: flex;
  padding: 0px;
  list-style-type: none;
}
.ratings ul li {
  margin: 0px 3px;
}
.specs ul {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  padding: 0px !important;
  width: 75%;
}
.specs li {
  color: #acacac;
  font-size: 17px;
  font-family: "MYRIADPRO-REGULAR";
}
.specs li img {
  margin-right: 5px;
}
.cardescription small a {
  color: #acacac;
}
/* pagination */
.paggination p {
  color: #999999;
}
/* pagination */
/* buyerLeaderboard */
.BuyersLeaderboard {
  background: url("../images/buyersleadermode.jpg");
  background-size: 100% 100%;
}

/* responsive quries */
@media (max-width: 1100px){
  .specs li {
    font-size: 12px;
  }
}
@media (max-width: 1024px){
  .specs li {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  figure img.car-img {
    height: auto !important;
  }
}
@media (max-width: 800px) {
  .specs ul {
    width: 100%;
  }
}

@media (max-width: 576px) {
  ul#Buycartabs .nav-link {
    font-size: 16px;
  }
  .car-content {
    padding-top: 16px !important;
  }
  .showcase-listing ul .find_box .img_box img {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .top-bar {
    display: block;
    text-align: center;
  }
  .top-bar ul#Buycartabs {
    justify-content: center;
  }
  .top-bar .cart-btn {
    margin-top: 30px !important;
    margin-bottom: 10px;
  }
  .carProduct .head h2.title {
    font-size: 30px;
  }
  .carProduct .head h2.title:before {
    left: 0px !important;
  }
  .specs ul {
    width: 100%;
  }
  .showcase .head h2.title {
    font-size: 34px;
  }
}

@media (max-width: 480px) {
  .showcase .head h2.title {
    font-size: 30px;
  }
}

@media (max-width: 320px) {
  .showcase .head h2.title {
    margin-right: 0px;
  }
  .showcase .head h2.title {
    font-size: 24px;
  }
}

/* responsive quries */
/* car listing tabs */
