/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* Begin of blog list   */

.blog-img{
    height: 200px;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
}

.blog-head h2 {
    font-size: 34px;
    color: #303842;
    font-weight: 600;
    text-transform: uppercase;
    padding: 30px 0px;
    font-family: 'HighSpeed';
}
.blog-head p {
    font-family: "A4SPEED-Bold";
    text-transform: uppercase;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    margin: 0;
}
.blog-head {
    text-align: center;
}
section.blog-head-sec {
    padding: 40px 0px;
}

section.blog-head-sec .linktag {
    display: flex;
    justify-content: center;
    align-items: center;
}
section.blog-head-sec .linktag .img-cont {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
section.blog-head-sec .linktag .img-cont img{
    width: 100%;
    height: 100%;
}
section.blog-head-sec .main-Title{
    text-transform: uppercase;
    font-size: 51px;
    font-family: "A4SPEED-Bold";
    text-align: center;
    color: #000;
}

/* End of Blog list */

/* Begin of blog card section  */
.blog-card-text h5 {
    font-size: 12px;
    color: #3F51B5;
    font-family:'Montserrat-Regular';
    font-weight: 500;
}
.blog-card-text h3 {
    font-size: 16px;
    color: #212121;
    font-family: 'Montserrat-Regular';
    font-weight: 700;
}
.blog-div-for-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.blog-sec .blog-div-for-flex h4 {
    font-size: 12px;
    color: #757575;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}
.blog-div-for-flex h4 span {
    color: #3F51B5;
    font-weight: 700;
}
.blog-card-text {
    padding: 20px;
}
.blog-all-stuff.card {
    border: none;
    box-shadow: 0px 0px 58px #0000000f;
}
section.blog-sec .col-md-3 {
    padding: 20px;
}
/* End of blog card section   */

/* Begin of trending section  */
.trend-sec .blog-img img {
    width: 100%;
}
.trend-text h3 {
    font-size: 13px;
    color: #000000;
    font-family: 'Poppins-Regular';
    font-weight: 500;
}
.blog-trendinf-post {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 6px;
}
.blog-all-stuff.card p {
    font-size: 13px;
    color: #757575;
    font-family: 'Poppins-Regular';
    font-weight: 400;
}
.trending-post-head h3 {
    font-size: 24px;
    color: #212121;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    padding-bottom: 20px;
}
.trending-post-btn {
    padding-bottom: 30px;
    text-align: end;
}
.trending-post-btn a {
    font-size: 14px;
    background-color: #3F51B5;
    color: #ffffff;
    padding: 6px 30px;
    font-family: 'Poppins-Regular';
    border-radius: 50px;
    font-weight: 500;
}
.for-space {
    margin-bottom: 12px;
}
section.trend-sec {
    padding: 70px 0px;
}
/* End of trending section  */
.blog-all-stuff .trending-img{
    /* height: 80px; */
    width: 80px;
    overflow: hidden;
}

/* Responsive section  */
@media (max-width: 1150px) {
    .blog-card-text h3 {
        font-size: 13px;
    }
    .trend-text h3 {
        font-size: 9px;
    }
    .blog-all-stuff.card p {
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .blog-head p {
        font-size: 13px;
    }
    .blog-card-text h3 {
        font-size: 8px;
    }
    .blog-div-for-flex h4 {
        font-size: 10px;
    }
    .blog-all-stuff.card p {
        font-size: 7px;
    }
    .trend-text {
        margin-left: 10px;
    }

}

@media (max-width: 480px) {
    section.blog-head-sec .main-Title {
        font-size: 34px;
    }
    .blog-head p {
        font-size: 10px;
    }
    section.blog-head-sec {
        padding: 28px 0px;
    }
    .blog-head h2 {
        font-size: 30px;
    }
    .blog-trendinf-post {
        display: flex;
        align-items: center;
    }
    .trending-post-btn {
        text-align: center;
        padding-top: 30px;
    }
}
/* End responsive section  */