.list-group-item {
    border: none;
    background-color: #EEEEEE;
    border-radius: unset !important;
}
.for-color {
    background-color: #D60808;
    border: 1px solid #D60808;
}
section.checkout-sec {
    padding: 50px;
}
.form-for-back h2 {
    font-size: 20px;
    color: #303842;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    padding: 15px 0px;
}
.form-for-back h6 {
    font-size: 17px;
    color: #303842;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    text-transform: uppercase;
}
.form-for-back span {
    font-size: 15px;
    color: #303842;
    font-family: 'Roboto-Regular';
    font-weight: 400;
    text-transform: uppercase;
}
select.form-control {
    border: none !important;
}
.for-back-color input {
    background-color: #EFEFEF !important;
    border: none;
    border-radius: unset;
    padding: 13px 24px;
    border: 1px solid #EFEFEF;
    outline: none;
}
.your-credit-card {
    font-size: 22px;
    color: #262626;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
    padding: 40px 0px;
}
.form-for-back {
    padding-bottom: 50px;
}
.form-check label {
    padding: 0;
    color: #000 !important;
}
.form-check {
    padding-left: 20px;
}
.checkout-sec .for-align {
    display: flex;
    align-items: center;
}
.checkout-sec button.btn.btn-primary.btn-lg.btn-block.for-color {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
    transition: 1s;
    border-radius: unset;
}
.checkout-sec label {
    font-size: 14px;
    color: #B3B5B8;
    font-weight: 400;
}
.checkout-sec button.btn.btn-primary.btn-lg.btn-block.for-color:hover {
    background-color: transparent;
    /* border: 1px solid !important; */
    color: #000;
    border: 1px solid;
}
.checkout-sec .credit-card input {
    padding: 14px;
    border-radius: 12px;
    position: relative;
}
.checkout-sec .credit-card input::placeholder {
    color: #B2B2B2;
}
.for-back-color input::placeholder {
    font-size: 15px;
    color: #B3B5B8;
    font-family: 'Montserrat-Regular';
    text-transform: capitalize;
}
.icon-lock {
    position: absolute;
    right: 34px;
    top: 10px;
    font-size: 30px;
    color: #d7d2d2;
}
.order-md-1 {
    padding: 0px 80px;
}
.checkout-sec .i-circle {
    position: absolute;
    right: 30px;
    top: 13px;
    font-size: 25px;
    color: #dbd8d8;
}

/* Responsive section  */
@media (max-width: 1150px) {

}

@media (max-width: 768px) {
    .order-md-1 {
        padding: 0px 0px;
    }
    .form-for-back h2 {
        font-size: 16px;
    }
    .form-for-back h6 {
        font-size: 13px;
    }
    .form-for-back span {
        font-size: 10px;
    }
    select.form-control {
        font-size: 8px;
    }
    .checkout-sec strong {
        font-size: 10px;
    }
    .checkout-sec label {
        font-size: 9px;
    }
}

@media (max-width: 480px) {
    .checkout-sec .col-md-4.order-md-2.mb-4 {
        padding: 0;
    }
    .checkout-sec label {
        font-size: 14px;
    }
}

@media (max-width: 375px) {
    section.checkout-sec {
        padding: 30px;
    }
}


/* End of responsive section  */