@import url("http://fonts.cdnfonts.com/css/myriad-pro");

.instant-top{
  padding: 45px 0px 0px 0px;
}

.instant-top .title{
  font-size: 32px;
  font-family: 'HighSpeed';
  text-align: center;
}

.instant-top .para{
  font-size: 14px;
  font-family: 'Poppins-Regular';
  text-align: center;
}

.inst-note{
  font-size: 14px;
  font-family: 'Poppins-Regular';
  text-align: center;
}

/* Begin of product details  */
.sellmycar-sec {
  padding: 40px 0;
}
.sellmycar-head h2 {
  font-size: 34px;
  color: #303842;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0px;
}
.sell-car-text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}
.sell-car-price h3 {
  font-size: 34px;
  color: #ff2e3e;
  font-family: "Roboto-Regular";
  font-weight: 300;
}
.sell-car-text1 h2 {
  font-size: 30px;
  color: #01050b;
  font-family: "Montserrat-Regular";
  font-weight: 700;
}
.sell-car-text1 h5 {
  font-size: 22px;
  color: #acacac;
  font-family: "Roboto-Regular";
  font-weight: 300;
}
.sellmycar-sec .description p {
  font-size: 14px;
  color: #707070;
  font-family: "Roboto-Regular";
}
.sellmycar-sec .description {
  padding-top: 20px;
}
section.nav-tabs-sec p {
  font-size: 14px;
  color: #707070;
  font-family: "Roboto-Regular";
  font-weight: 400;
}
section.nav-tabs-sec .nav-tabs {
  border-bottom: unset;
}
section.nav-tabs-sec .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #464a4c;
  background-color: #fff;
  border: none;
  background: #ff2e3e;
  color: #ffffff;
  border-radius: unset;
}
section.nav-tabs-sec a.nav-link {
  font-size: 15px;
  color: #303842;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  padding: 5px 20px;
  margin-right: 34px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.rec-text-1 h3 {
  font-size: 18px;
  color: #01050b;
  font-family: "MYRIADPRO-REGULAR";
  font-weight: 700;
  text-transform: uppercase;
}
.rec-text-1 h5 a{
  font-size: 18px;
  color: #acacac;
  font-family: "MYRIADPRO-REGULAR";
  font-weight: 700;
  letter-spacing: 1px;
}
.rec-text-1 h5.for-uppercase {
  text-transform: uppercase;
}
.rec-text-1 h4 {
  font-size: 19px;
  color: #ff2e3e;
  font-family: "MYRIADPRO-REGULAR";
  font-weight: 700;
}
.rec-1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 20px;
}
.rec-1 img {
  height: 130px;
  width: 150px;
  object-fit: cover;
}
.rec-head h2 {
  font-size: 25px;
  color: #303842;
  font-family: "Roboto-Regular";
  font-weight: 400;
  text-transform: uppercase;
  padding: 20px 0px;
}
.selller-contact-btn a {
  background-image: url(../images/contactformsubmit.png);
  background-repeat: no-repeat;
  display: block;
  padding: 15px 100px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat-Regular";
  font-weight: 700;
  text-transform: capitalize;
}
.selller-contact-btn .contact-chat {
  background-image: url(../images/contactformsubmit.png);
  background-repeat: no-repeat;
  display: block;
  padding: 15px 100px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Montserrat-Regular";
  font-weight: 700;
  text-transform: capitalize;
  border: unset;
}

.sell-car-img2 img {
  padding-bottom: 30px;
  filter: brightness(0.5);
}
.selller-contact-btn {
  margin-top: 50px;
}
section.nav-tabs-sec {
  padding: 50px 0px;
}
.sellmycar-sec .button-group {
  text-align: center;
}
.sellmycar-sec .button-group .instant-offer {
  background: #ff2e3e;
  border-radius: 0;
  color: #fff;
  font-family: "Roboto-Regular";
  width: 100%;
}
.sellmycar-sec .single-images {
  margin-top: 10px;
}
.sellmycar-sec .single-images .img_box {
  margin-right: 7px;
}
.sellmycar-sec .make-offer {
  margin-top: 30px;
}
.sellmycar-sec .make-offer h4 {
  font-size: 30px;
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
}
.sellmycar-sec .make-offer .form-group {
  margin: 0;
  display: flex;
}
.sellmycar-sec .make-offer .form-group .form-control {
  border: 1px solid #707070;
  border-radius: 4px 0 0 4px;
  border-right: 0;
  font-family: "Roboto-Regular";
  font-size: 14px;
  height: 45px;
  padding: 10px 20px;
}
.sellmycar-sec .make-offer .form-group button {
  background: #d10808;
  border: 0;
  color: #fff;
  font-size: 20px;
  font-family: "Montserrat-Regular";
  font-weight: 500;
  padding: 2px 28px;
  cursor: pointer;
  border-radius: 4px;
}
/* End of product details  */

.instant-imgSlider .inner-image-container{
  padding: 15px;
}

/* Begin of responsive  */
@media (max-width: 1150px) {
  .rec-text-1 h3 {
    font-size: 21px;
  }
  .rec-text-1 h5 {
    font-size: 14px;
  }
  .rec-1 {
    justify-content: space-between;
  }
}
@media (max-width: 1100px) {
  .rec-text-1 h3 {
    font-size: 18px;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    font-size: 14px;
    border: unset;
    background-color: #dd0000;
}
  
}

@media (max-width: 800px) {
  .rec-1 {
    display: block;
  }
  .rec-1 img {
    height: 130px;
    width: 100% !important;
  }
  section.nav-tabs-sec a.nav-link {
    font-size: 12px;
  }
  .sellmycar-head h2 {
    font-size: 34px;
    padding: 10px 0px;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    font-size: 14px;
    margin-top: 12px;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    font-size: 14px;
    margin-top: 12px;
    margin-left: 17px;
    width: 70%;
}
}


@media (max-width: 768px) {
  .rec-text-1 h3 {
    font-size: 16px;
  }
  .rec-text-1 h5 {
    font-size: 10px;
  }
  .rec-text-1 h4 {
    font-size: 24px;
  }
  section.nav-tabs-sec p {
    font-size: 9px;
  }

  .rec-head h2 {
    font-size: 18px;
  }
  .sell-car-text1 h2 {
    font-size: 34px;
  }
  .sell-car-price h3 {
    font-size: 34px;
  }
  .sell-car-text {
    align-items: baseline;
  }
  .sell-car-img2 img {
    padding-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .selller-contact-btn .contact-chat {
    font-size: 14px;
    padding: 15px 100px;
    margin-top: 10px;
    width: 100%;
    margin-left: unset !important;
    /* margin: 0 auto; */
  }

  section.nav-tabs-sec a.nav-link {
    font-size: 12px;
    margin-right: 0px;
  }
  .sell-car-text1 h2 {
    font-size: 24px;
  }
  .sell-car-text {
    align-items: flex-start;
  }
  .sellmycar-head h2 {
    font-size: 26px;
  }
  .sell-car-price h3 {
    font-size: 25px;
  }
  .selller-contact-btn a {
    font-size: 16px;
  }
  .rec-1 {
    justify-content: space-between;
  }
  .rec-1 img {
    height: 200px;
    width: 100% !important;
    object-fit: cover;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 80px;
    font-size: 14px;
    width: 70%;
  }
    section.nav-tabs-sec {
      padding: 0px 0px;
    }

}

@media (max-width: 375px) {
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    width: 100%;
    margin-left: unset !important;
    margin-top: 10px;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    width: 100%;
    margin-left: unset !important;
    margin-top: 10px;
  }
}

@media (max-width: 360px) {
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    width: 100%;
    margin-left: unset !important;
    margin-top: 10px;
  }
  .selller-contact-btn .contact-chat {
    padding: 15px 100px;
    width: 100%;
    margin-left: unset !important;
    margin-top: 10px;
  }
}


