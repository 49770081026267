.events {
    padding: 80px 0px;
  }
  .events .eventlist{
    text-align: center;
  }
  .events h2,
  .create-events h2 {
    color: #303842;
    font-size: 34px;
    font-weight: bold;
    font-family: 'HighSpeed';
  }
  .events p,
  .create-events p {
    color: #8191a6;
    font-size: 15px;
    font-family: "Roboto-Regular";
  }
  .event-card {
    position: relative;
    overflow: hidden;
  }
  .event-details-content h2,
  .event-details-content p,
  .reservation-form-content h2 {
    padding-left: 16px;
  }
  .event-card .e-img {
    height: 331px;
    width: 100%;
  }
  .event-card .contentDv {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0%;
    text-align: center;
    background: #00000080;
    transition: 0.6s;
  }
  .event-card:hover .contentDv {
    height: 100%;
    top: 0;
  }
  .event-card figcaption {
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    line-height: 32px;
  }
  .event-details {
    background: #f8f8f8;
  }
  .event-details-content h2,
  .reservation-form-content h2 {
    font-family: 'Montserrat-Regular';
    font-weight: 700;
    color: #303842;
    line-height: 45px;
  }
  .reservation-form-content .form-group.row {
    margin-left: 0px !important;
  }
  .reservation-form-content .createEvent-input {
    border: 0px !important;
    background: #ececec;
    padding: 12px;
    border-radius: 0px !important;
    font-family: "Roboto-Regular";
  }
  .reservation-form-content input::placeholder {
    color: #b4b4b4;
  }
  .reservation-form-content textarea {
    border: 0px !important;
    background: #ececec;
    padding: 12px;
    border-radius: 0px !important;
    font-family: "Roboto-Regular";
    height: 140px;
  }
  .reservation-form-content textarea::placeholder {
    color: #b4b4b4;
  }
  .reservation-form-content .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    width: 75%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-size: 18px;
    background-size: 100% 100%;
    font-weight: 500;
  }
  .event-list {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url("../images/event-list-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .event-list h2 {
    font-family: "HighSpeed";
    font-size: 27px;
    font-style: italic;
  }
  .event-list p {
    font-family: "HighSpeed";
    font-size: 15px;
    font-style: italic;
  }
  .event-partners {
    display: flex;
    justify-content: space-between;
  }
  .event-partners .partners-name h3 {
    color: #fff;
    font-family: "Roboto-Regular";
    font-size: 30px;
    font-weight: bold;
    transition: text-decoration 0.4s ease-in-out;
  }
  .cool-link::after {
    content: "";
    display: block;
    width: 0;
    height: 5px;
    background: #fff;
    transition: width 0.3s;
  }
  .cool-link:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
  /* event 1 page css start here  */
  .edit-event-form .form-group #location {
    background-image: url(../images/event-loc.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .edit-event-form input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url("../images/date-range.svg");
  }
  .edit-event-form select {
    background-image: url("../images/select-dropdown.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center right 10px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
  .edit-event-form input {
    padding: 11px 5px;
    border-radius: 0px !important;
    border: 0px !important;
    background: #ececec;
    border-left: 10px solid #d70808 !important;
  }
  .edit-event-form select {
    height: 42px !important;
    border-radius: 0px !important;
    border: 0px !important;
    background: #ececec;
    border-left: 10px solid #d70808 !important;
  }
  .edit-event-form input#uploadimage {
    background: no-repeat;
    border: 0px !important;
  }
  .edit-event-form .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 74%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
  }
  .events .contentDv .title h3 {
    font-size: 22px;
    color: #d50808;
    font-weight: 600;
    font-family: Montserrat-Regular;
  }
  .events .contentDv .title {
    padding-bottom: 15px;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 15px;
  }
  .events .contentDv .details p {
    font-size: 20px;
    color: #303842;
    font-family: 'Poppins-Regular';
    margin: 30px 0;
  }
  .events .contentDv .description {
    padding-top: 0px;
  }
  .events .contentDv .description h5 {
    font-family: 'Poppins-Regular';
  }
  .events .contentDv .description p {
    font-size: 17px;
    line-height: 28px;
    color: #000000;
    padding-bottom: 10px;
  }
  .events .contentDv button.btn {
    background: url("../images/contactformsubmit.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 40px 20px 0px;
    width: 65%;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-weight: 500;
  }
  /* event 1 page css start here  */
  @media (max-width: 1100px) {
    .event-card .e-img {
      height: 273px;
      width: 100%;
    }
  }
  @media (max-width: 769px) {
    .event-card .e-img {
      height: 405px;
    }
    .events .event-card {
      margin-top: 20px;
    }
    .event-details-img {
      text-align: center;
    }
    .reservation-form-content .form-group .col-lg-6 {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 480px) {
    .event-list h2 {
      font-size: 20px;
    }
    .event-card {
      text-align: center;
    }
    .event-card .e-img {
      width: 310px;
    }
    .event-card .contentDv {
      width: 69%;
      margin: 0px auto;
    }
    .event-partners {
      display: block;
    }
    .event-partners .partners-name {
      display: inline-block;
      width: 20%;
      text-align: center;
      margin: 20px 0px !important;
    }
    .event-partners .partners-name h3 {
      font-size: 16px;
  }
    .events .contentDv .title {
      margin-top: 10px;
  }
  .event-partners .partners-name h3 {
    font-size: 25px;
  }
  }

  