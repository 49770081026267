.leaderboard-content p {
    color: #8191a6;
    font-size: 15px;
    font-family: "Roboto-Regular";
  }
  .leaderboard-content h2 {
    color: #303842;
    font-size: 34px;
    font-family: "Montserrat-Regular";
  }
  .leaders table {
      width: 100%;
  }
  .rating ul {
      padding: 0px !important;
      list-style: none;
      display: flex;
  }
  .rating .star {
      margin: 2px;
  }
  .leaders td.rank {
      font-size: 53px;
      font-family: 'Poppins-Regular';
      color: #000000;
      text-align: center;
  }
  .leaders .leader-number {
      color: #303842;
      font-size: 21px;
      font-weight: 600;
      font-family: 'Poppins-Regular';
      line-height: 32px;
  }
  .leaders h2 {
      font-size: 30px;
      color: #000000;
      font-weight: 600;
  }
  section.leaders .leader-img img {
      border: 1px solid;
      border-radius: 50%;
      padding: 10px;
  }
  .leaders .leader-points {
      border: 1px solid #244638;
      text-align: center;
      padding: 9px;
      border-radius: 11px;
      background: #fff;
      font-family: 'Poppins-Regular';
      font-size: 19px;
      color: #000;
      width: 80%;
      margin: 0px auto;
  }
  .rating {
      text-align: center;
      width: 74%;
      margin: 0px auto;
  }
  .leaders td.name-number {
      padding: 0px 26px;
  }
  .leaders tr td {
      padding: 19px 20px!important;
      background: #F3F3F3 !important;
      border-radius: 2px !important;
  }
  .leaders tr {
      border-bottom: 12px solid #fff;
  }
  .leaders button.load-more {
      padding: 10px 148px;
      background: url('../images/back.png');
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      background-color: transparent;
      font-family: 'Poppins-Regular';
      color: #fff;
  }