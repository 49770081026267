.login-form .form-group input {
    padding: 17px;
    border: 1px solid #244638;
    border-radius: 5px;
    font-weight: 300 !important;
    cursor: pointer;
    width: 100%;
  }
  .login-form .form-group input::placeholder {
    color: #a5a5a5;
    font-family: 'Poppins-Regular';
    font-weight: 300 !important;
  }
  .login-form .form-group input#Email {
    background-image: url(../images/email.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .login-form .form-group input#Password {
    background-image: url(../images/padlock.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
  }
  .login-form .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
    font-family: 'Poppins-Regular';
    font-weight: 600 !important;
  }
  .newshow p {
    color: #000 !important;
    font-weight: 400;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .newshow a {
    color: #000 !important;
    font-weight: 600;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .forpass a {
    color: #000 !important;
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  .login-form-content h2 {
    font-family: 'Poppins-Regular';
    font-size: 40px;
  }
  .login-form-content p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
  }
  @media (max-width: 576px) {
    .login-form .form-submit {
      background-position: center;
    }
  }
  @media (max-width: 480px) {
    .loginasseller {
      margin: 0px !important;
    }
    .login-form .form-submit {
      background-position: right center;
    }
    .login .forpass {
      margin: 0px !important;
    }
  }
  