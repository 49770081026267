body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Neutro-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Neutro-ExtraBold'), url('./assets/fonts/Neutro-ExtraBold.otf') format('woff');
}

@font-face {
  font-family: 'gadugi';
  font-style: normal;
  font-weight: normal;
  src: local('gadugi'), url('./assets/fonts/gadugi-2.ttf') format('woff');
}


@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Regular'), url('./assets/fonts/Poppins-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Bold'), url('./assets/fonts/Poppins-Bold.ttf') format('woff');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-SemiBold'), url('./assets/fonts/Poppins-SemiBold.ttf') format('woff');
}
@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Medium'), url('./assets/fonts/Poppins-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Bold'), url('./assets/fonts/Montserrat-Bold.ttf') format('woff');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-SemiBold'), url('./assets/fonts/Montserrat-SemiBold.ttf') format('woff');
}
@font-face {
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat-Medium.ttf') format('woff');
}


@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto-Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Italic'), url('./assets/fonts/Roboto-Italic.ttf') format('woff');
}

@font-face {
  font-family: 'HighSpeed';
  font-style: normal;
  font-weight: normal;
  src: local('HighSpeed'), url('./assets/fonts/High\ Speed.ttf') format('woff');
}

@font-face {
  font-family: 'MYRIADPRO-REGULAR';
  font-style: normal;
  font-weight: normal;
  src: local('MYRIADPRO-REGULAR'), url('./assets/fonts/MYRIADPRO-REGULAR.OTF') format('woff');
}

@font-face {
  font-family: 'A4SPEED-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('A4SPEED-Bold'), url('./assets/fonts/A4SPEED-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Impact';
  font-style: normal;
  font-weight: normal;
  src: local('Impact'), url('./assets/fonts/impact.ttf') format('woff');
}

@font-face {
  font-family: 'LacostaLine';
  font-style: normal;
  font-weight: normal;
  src: local('LacostaLine'), url('./assets/fonts/LacostaLine_PERSONAL_USE_ONLY.ttf') format('woff');
}

@font-face {
  font-family: 'APOLLO';
  font-style: normal;
  font-weight: normal;
  src: local('APOLLO'), url('./assets/fonts/APOLLO.otf') format('woff');
}

@font-face {
  font-family: 'KonexyPersonalUse';
  font-style: normal;
  font-weight: normal;
  src: local('KonexyPersonalUse'), url('./assets/fonts/KonexyPersonalUse.otf') format('woff');
}

@font-face {
  font-family: 'Dolce Vita';
  font-style: normal;
  font-weight: normal;
  src: local('Dolce Vita'), url('./assets/fonts/Dolce\ Vita.ttf') format('woff');
}
@font-face {
  font-family: 'moon_get-Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('moon_get-Heavy'), url('./assets/fonts/moon_get-Heavy.ttf') format('woff');
}

@font-face {
  font-family: 'Barbara';
  font-style: normal;
  font-weight: normal;
  src: local('Barbara'), url('./assets/fonts/Barbara.ttf') format('woff');
}
