.breadcumbs {
    padding-top: 60px;
    /* padding-bottom: 100px; */
}
.breadcumbs .breadcrumb {
    background: transparent;
}
.breadcumbs .breadcrumb-item+.breadcrumb-item::before {
    content: '>';
}
.breadcumbs ol.breadcrumb a {
    color: #000;
    transition: 0.6s;
}
.breadcumbs .breadcrumb .breadcrumb-item {
    color: #000;
    font-family: 'Montserrat-Regular';
    font-size: 17px;
    font-weight: 400;
}
.breadcumbs ol.breadcrumb a:hover {
    color: #148BE7;
}
.side-bar-filter h4 {
    font-size: 14px;
    color: #404040;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
}
.side-bar-filter {
    border-bottom: 1px solid #B2B2B2;
    padding-bottom: 0px;
}
.side-bar-filter h3 {
    padding: 14px 0px;
}
.sider-bar-colors h3 {
    padding: 40px 0px;
}
/* This css is for normalizing styles. You can skip this. */
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .form-group {
    display: block;
    margin-bottom: 0px;
}
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    /* display: none; */
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  /* .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #262626;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  } */
  .form-check-color {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
}
  /* .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } */
  .div-color {
    width: 20px;
    height: 20px;
    background-color: #000000;
    border-radius: 40px;
}
.side-bar-color-btn a {
    background-color: #262626;
    color: #ffffff;
    padding: 12px 30px;
    font-size: 21px;
    font-family: 'Montserrat-Regular';
    font-weight: 600;
}
.side-bar-color-btn {
    text-align: center;
    border-bottom: 1px solid #B2B2B2;
    line-height: 171px;
}
.tag-product-anchor a {
    font-size: 16px;
    color: #404040;
    border: 1px solid;
    padding: 10px;
}
.tag-product-anchor {
    padding: 15px 0px;
}
.side-bar-tag-product h3 {
    font-size: 30px !important;
    padding-top: 40px;
    padding-bottom: 30px;
}
.sider-bar-brand-div h3 {
    padding-top: 60px;
    border-top: 1px solid #B2B2B2;
}
.side-bar-tag-product {
    padding-bottom: 50px;
}
.icons-featured {
    display: flex;
    align-items: center;
}
.featured-and-icon i {
    font-size: 22px;
    background-color: #B2B2B2;
    padding: 10px;
    margin-left: 29px;
    color: #ffffff;
    transition: 0.6s;
}
.featured-and-icon i:hover {
    background-color: #EC4A4A;
}
.featured-and-icon .dropdown, .dropup {
    position: relative;
    left: 40px;
}
.featured-and-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icons-heading-feature h3 {
    font-size: 22px;
    color: #262626;
    font-family: 'Montserrat-Regular';
    font-weight: 500;
}
/* featured products section css starts here */
.featured-products h3.sidebar-heading {
    font-size: 21px;
    font-weight: 600;
    font-family: 'Montserrat-Regular';
}
.featured-products ul.categories {
    padding: 0px;
    list-style-type: none;
    margin-top: 50px;
    margin-bottom: 50px;
}
.featured-products ul.categories li.categories-item {
    padding: 15px 0px;
    font-size: 18px;
    font-family: 'Montserrat-Regular';
}
.featured-products ul.categories a.categories-link {
    color: #404040;
}
.featured-products ul.categories a.categories-link:hover {
    color: #FF4E00;
}
.featured-products ul.categories li.categories-item {
    padding: 15px 0px;
    font-size: 18px;
    font-family: 'Montserrat-Regular';
    border-bottom: 1px solid #B2B2B2;
}
.merchan-stars ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.merchan-stars ul li a i {
    color: #FFC400;
    margin-right: 12px;
    font-size: 20px;
}
.merchan-head h3 {
    font-size: 22px;
    color: #262626;
    font-family: 'Montserrat-Regular';
    font-weight: 600;
}
.merchan-head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 25px;
    padding: 12px 21px;
}
.merchan-head .heart {
    width: 22px;
}
.merchan-para p {
    font-size: 20px;
    color: #707070;
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    padding-top: 10px;
    padding-left: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.merchan-head h4 {
    font-size: 40px;
    color: #707070;
    font-family: 'Montserrat-Regular';
    font-weight: 600;
}
.merchan-stars {
    padding-top: 30px;
    padding-left: 20px;
}
.merchan-all {
    padding: 30px;
}
.merchan-line{
    border-bottom: 1px solid #B2B2B2;
}
/* featured products section css ends here */



/* Begin of range Slider  */
input[type=range] {
    box-sizing: border-box;
    appearance: none;
    width: 100%;
    height: 10px;
    background-color: #b2b2b2;
    border-radius: 50px;
    margin: 0;
    border: 0;
    outline: none;
    background-size: 100% 2px;
    pointer-events: none;
    box-shadow: inset 0 0 4px #000000;
 
    &:active,
    &:focus {
       outline: none;
    }
 }
 input[type=range] {
   -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
   width: 100%; /* Specific width is required for Firefox. */
 }
 
 input[type=range]::-webkit-slider-thumb {
   -webkit-appearance: none;
 }
 
 input[type=range]:focus {
   outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
 }
 
 input[type=range]::-ms-track {
   width: 100%;
   cursor: pointer;
 
   /* Hides the slider so custom styles can be added */
   background: transparent; 
   border-color: transparent;
   color: transparent;
 }
 /* Special styling for WebKit/Blink */
 input[type=range]::-webkit-slider-thumb {
   height: 28px;
    width: 28px;
    border-radius: 28px;
    border: solid 1px #b2b2b2;
    background-color: #fff;
    position: relative;
    z-index: 50;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
 }
 
 /* All the same stuff for Firefox */
 input[type=range]::-moz-range-thumb {
   height: 28px;
    width: 28px;
    border-radius: 28px;
    border: solid 1px #b2b2b2;
    background-color: #fff;
    position: relative;
    z-index: 50;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
 }
 
 /* All the same stuff for IE */
 input[type=range]::-ms-thumb {
   height: 28px;
    width: 28px;
    border-radius: 28px;
    border: solid 1px #b2b2b2;
    background-color: #fff;
    position: relative;
    z-index: 10000;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
 }
 
 
 .multi-range-container {
    max-width: 400px;
 }
 
 .multi-range {
    position: relative;
    height: 50px;
    display: block;
    width: 100%;
 }
    input[type=range] {
       position: absolute;
    }
 .range-color {
    background-color: #F8F8F9;
    border-radius: 50px;
    width: 100%;
    display: block;
    height: 10px;
    position: absolute;
    z-index: 10;
 }
 
 
 input[type=range]::-ms-track {
   width: 100%;
   height: 8.4px;
   cursor: pointer;
   background: transparent;
   border-color: transparent;
   border-width: 16px 0;
   color: transparent;
 }
 input[type=range]::-ms-fill-lower {
   background: #2a6495;
   border: 0.2px solid #010101;
   border-radius: 2.6px;
   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
 }
 input[type=range]:focus::-ms-fill-lower {
   background: #3071a9;
 }
 input[type=range]::-ms-fill-upper {
   background: #3071a9;
   border: 0.2px solid #010101;
   border-radius: 2.6px;
   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
 }
 input[type=range]:focus::-ms-fill-upper {
   background: #367ebd;
 }
 /* End of Range slider  */

 /* Begin of responsive  */
@media (max-width:1150px){
    .featured-products h3.sidebar-heading {
        font-size: 18px;
    }
    .side-bar-tag-product h3 {
        font-size: 26px !important;
    }
    .merchan-para p {
        font-size: 16px;
    }
    .merchan-head h4 {
        font-size: 30px;
    }
}

@media (max-width:768px){
    
}


@media (max-width:480px){
    .featured-and-icon {
        display: unset;
    }
    .icons-heading-feature h3 {
        padding-top: 20px;
    }
}
/* End of responsive  */



 
 
 
 
 


