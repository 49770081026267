.merch p {
    color: #8191a6;
    font-size: 15px;
    font-family: "Roboto-Regular";
  }
  .merch h2 {
    color: #303842;
    font-size: 34px;
    font-family: "Montserrat-Regular";
    text-transform: uppercase;
  }
  .merchandise .merchandise-img {
    filter: brightness(0.5);
    height: 100px;
    width: 100%;
  }
  .merchandise figure {
    border-right: 30px solid #d60808;
  }
  .merchandise figcaption {
    position: absolute;
    bottom: 0px;
    left: 38px;
    font-size: 35px;
    font-family: "Montserrat-Regular";
    font-weight: 700;
  }
  .merchandise .ii {
    height: 510px;
  }
  @media (max-width: 1100px) {
    .merchandise .ii {
      height: 417px;
    }
    .merchandise figcaption {
      font-size: 31px;
    }
  }
  @media (max-width: 769px) {
    .merchandise .ii {
      height: 318px;
      object-fit: cover;
    }
  }
  @media (max-width: 576px) {
    .merchandise .merchandise-img {
      width: 100%;
    }
    .merchandise {
      position: relative;
      margin: 20px 0px;
    }
    .merchandise figure {
      border-right: unset;
  }

  }
  