.signup h2{
    font-size: 40px;
    font-family: 'Poppins-Regular';
}
.signup p {
    font-family: 'Poppins-Regular';
    font-size: 14px;
}
.signup .form-group input {
    padding: 17px;
    border: 1px solid #244638;
    border-radius: 5px;
    width: 100%;
    font-weight: 300 !important;
}
.signup .form-group input::placeholder {
    color: #A5A5A5;
    font-family: 'Poppins-Regular';
    font-weight: 300 !important;
}
.signup .form-group input#username {
    background-image: url(../images/name.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#Email {
    background-image: url(../images/email.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#Address{
    background-image: url(../images/LOC.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#city{
    background-image: url(../images/LOC.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#Zip{
    background-image: url(../images/LOC.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#Phone{
    background-image: url(../images/phone.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#Password{
    background-image: url(../images/padlock.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-group input#confirm-Password{
    background-image: url(../images/padlock.svg);
    background-repeat: no-repeat;
    background-position: center right 10px !important;
}
.signup .form-submit {
    display: block;
    background: url("../images/contactformsubmit.png");
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding: 14px;
    border: 0px !important;
    color: #fff;
  }
.signup input#signupsubmit {
    font-family: 'Poppins-Regular';
    font-size: 18px;
}
@media (max-width:480px){
    .signup-form input#signupsubmit {
        margin: 0px !important;
    }
    .signup .form-submit {
        display: block;
        background: #dd0808;
        padding: 14px;
        width: 92%;
        margin: 0 auto;
    }
    .signup-form form .row {
        justify-content:  unset !important;
    }
}
@media (max-width:380px){
    .signup-form input#signupsubmit {
        width: 92%;
        transform: translateX(17px);
    }
}